.copy-wrapper {
	cursor: pointer;
	width: fit-content;

	.copy-icon {
		color: #6881e9;
		font-size: 16px;
		position: absolute;
		right: 0px;
		pointer-events: none;
		z-index: 1;

		@media screen and (max-width: 460px) {
			display: none;
		}
	}

	p {
		font-weight: 300;
		font-size: 14px;
		color: black;
		margin: 0;
		position: relative;
		display: flex;
		align-items: center;
		gap: 10px;
		padding-right: 20px;

		.copied-txt {
			display: block;
			position: absolute;
			left: 50%;
			bottom: calc(100% - 10px);
			transform: translateX(-50%);
			padding: 2px 7px;
			border-radius: 4px;
			background-color: rgb(30, 30, 30);
			color: white;
			opacity: 0;
			transition: 0.2s;
			pointer-events: none;
			&::after {
				content: 'Copied';
			}
		}

		&.copied {
			.copied-txt {
				opacity: 1;
				bottom: 100%;
			}
		}
	}
}
