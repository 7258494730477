.pagination {
	display: flex;
	gap: 10px;
	align-items: center;

	p.page-number {
		font-weight: 500;
		font-size: 14px;
		color: #b4b4b4;
		margin: 0;
		cursor: pointer;

		&.active {
			pointer-events: none;
			color: #949494;
		}
	}

	.pagination-icon {
		margin: 0;
		font-size: 10px;
		opacity: 0.4;
		cursor: pointer;

		&.disable {
			pointer-events: none;
			opacity: 0.2;
		}
	}

	.double-prev-icon,
	.double-next-icon {
		display: flex;
		margin: 0;

		.pagination-icon {
			&:last-of-type {
				margin-left: -6px;
			}
		}
	}
}
