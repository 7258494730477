@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.website-mode {
  .checkout {
    padding-top: 0;

    .page-titles {
      padding-top: 0;
      margin-top: 0;
    }

    @media screen and (max-width: $breakpoint-3) {
      .page-titles {
        .back-btn {
          font-size: 14px;
        }
      }
    }

    &.post-checkout {
      .page-titles {
        margin-top: 40px;
      }

      .checkout-content {
        margin-top: 40px;
      }
    }
  }
}

.checkout {
  $maxWidth: 1300px;
  $bg: #f7f7ff;
  $font-color: #1c3144;
  padding: 20px 40px;
  position: relative;
  color: $font-color;

  > .wrapper {
    margin: 0 auto;
    max-width: $maxWidth;
    width: 100%;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    margin: 0 0 20px;
  }

  .page-titles {
    margin-bottom: 70px;
    color: #fff;
    margin-top: 20px;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 44px;
      line-height: 130%;

      @media screen and (max-width: $breakpoint-3) {
        font-size: 34px;
      }
    }

    h4 {
      font-weight: 300;
      font-size: 30px;
      line-height: 124.5%;
      margin: 0;

      @media screen and (max-width: $breakpoint-3) {
        font-size: 20px;
      }

      .back-btn {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #fff;
        background: none;
        border: none;
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.post-checkout {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5em;

    .page-titles {
      margin-bottom: 0;

      h2 {
        margin-bottom: 0;
      }
    }

    .checkout-content {
      margin: 20px auto;
      padding: 40px;
      width: 820px;
      max-width: 100%;
      background: linear-gradient(
        113.11deg,
        rgba(255, 255, 255, 0.6) 0.6%,
        rgba(255, 255, 255, 0.6) 0.61%,
        rgba(255, 255, 255, 0.08) 99.61%
      );
      box-shadow: 0px 0px 60px rgb(0 0 0 / 15%);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(20px);
      outline: 3px solid #ffffff1a;
      outline-offset: -3px;
      border-radius: 20px;
    }
  }

  @media screen and (max-width: $breakpoint-4) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .service-name {
    color: #6881E9;
  }

  .content-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 200px;
    background: none;
    max-width: 100%;
    box-shadow: none;
    border-radius: 0;

    @media screen and (max-width: $breakpoint-2) {
      gap: 100px;
    }

    @media screen and (max-width: $breakpoint-3) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    .processing {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.9);
      z-index: 10;

      .loader p {
        margin-left: -15px;
      }
    }

    .pricing-calc {
      padding: 30px;
      background: linear-gradient(
        113.11deg,
        rgba(255, 255, 255, 0.8) 0.6%,
        rgba(255, 255, 255, 0.3) 99.61%
      );
      box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(40px);
      /* Note: backdrop-filter has minimal browser support */
      border-radius: 12px;
      width: 470px;
      max-width: 100%;
      position: sticky;
      top: 30px;
      font-size: 18px;
      margin: 0 auto;

      @media screen and (max-width: $breakpoint-3) {
        position: relative;
        top: 0;
      }

      .billing-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        > * {
          flex: 1;
          text-align: left;
        }

        .discount {
          color: #2fcb51;
        }

        strong:last-child {
          text-align: right;
        }

        &.total {
          font-size: 18px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          padding-top: 30px;
          margin-top: 30px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(
              0.25turn,
              $font-color,
              $font-color,
              #f7f7ff05
            );
            z-index: 0;
          }

          strong:last-child {
            font-weight: 700;
          }

          i {
            display: block;
            font-size: 12px;
            text-align: right;
            font-weight: 200;
            margin-top: 3px;
          }
        }
      }
    }

    .change-plan-checkout {
      text-align: left;
      margin: 60px 0;
      font-size: 20px;

      p {
        margin: 0 0 20px;

        span {
          margin-top: 10px;
          font-size: 14px;
          display: inline-block;
        }
      }

      .btn {
        font-size: 18px;
        text-transform: uppercase;
        width: 100%;
        max-width: $maxWidth;
      }
    }

    .payment-methods {
      padding: 20px 0 40px;
      flex: 1;
    }

    .paypal {
      margin-bottom: 50px;
    }

    .paypal-form {
      max-width: 500px;

      @media screen and (max-width: $breakpoint-3) {
        margin: 0 auto;
      }
    }

    .credit {
      position: relative;

      @media screen and (max-width: $breakpoint-3) {
        max-width: 550px;
        margin: 0 auto;
      }

      .form {
        position: relative;

        .form-row {
          display: inline-flex;
          width: 100%;

          &.half {
            width: 50%;

            @media screen and (max-width: $breakpoint-3) {
              width: 100%;
            }

            &:nth-child(even) {
              padding-left: 25px;

              @media screen and (max-width: $breakpoint-3) {
                padding-left: 0;
              }

              label {
                width: 120px;
                flex-basis: 120px;

                @media screen and (max-width: $breakpoint-3) {
                  flex-basis: 0;
                }
              }
            }
          }
        }

        .form-row > label {
          min-height: 20px;
          font-size: 15px;
          line-height: 23px;
          color: $font-color;
          text-transform: none;
          font-weight: normal;
        }

        @media screen and (max-width: $breakpoint-4) {
          padding: 15px;
        }
      }
    }

    input[type='text'],
    input[type='number'],
    select {
      width: 100%;
      border: 1px solid rgba(28, 49, 68, 0.5);
      border-radius: 6px;
      background: none;
      font-family: 'Reboto', sans-serif;
      color: $font-color;

      &::placeholder {
        color: #757575;
      }

      &:focus {
        background: rgba(255, 255, 255, 0.75);
      }

      &.small {
        max-width: 180px;
      }

      @media screen and (max-width: $breakpoint-4) {
        width: 100%;
      }
    }

    select {
      max-width: 200px;
      width: 100%;
    }

    .credit-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 0;
      top: 5px;
      width: 100%;
      padding-left: 220px;

      @media screen and (max-width: $breakpoint-3) {
        position: relative;
        padding-left: 0;
        justify-content: center;
        top: 0;
        margin-bottom: 20px;
      }

      img {
        margin: 0 5px;
        max-height: 15px;
        box-shadow: 0 0 0 2px #fff, 0 0 0 3px #dce0e9;
      }
    }

    .card-number {
      align-items: flex-start;

      label {
        margin-top: 10px;

        @media screen and (max-width: $breakpoint-4) {
          margin-top: 0;
        }
      }

      .hosted {
        position: relative;

        *[data-bluesnap] {
          max-width: 100%;
        }
      }
    }

    .buttons-wrapper {
      margin: 0 0 20px;
      padding-top: 10px;

      .checkout-button {
        background: #2fcb51;
        border-radius: 6px;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #eeeef6;
        border: none;
        display: block;
        padding: 20px 40px;
        width: 100%;
        cursor: pointer;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.1);
          opacity: 0;
          transition: opacity 0.2s ease;
        }

        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  .bluesnap-disclaimer {
    font-size: 14px;
    line-height: 16px;
    color: #b5b5b5;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;

    @media screen and (max-width: $breakpoint-3) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    img {
      max-height: 40px;
      margin-right: 10px;

      @media screen and (max-width: $breakpoint-3) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}

// Bluesnap annoying fraud iframe
#bs-fraud-sid {
  display: none;
}
