@import "~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars";

.developer-menu-hamburger {
  cursor: pointer;
  display: none;
  opacity: 1;
  transition: 0.2s;
  padding: 5px 5px 0px 5px;
  background: white;
  border: 1px solid rgba(8, 8, 52, 0.271);
  border-radius: 5px;

  .icon {
    color: rgba(8, 8, 52, 0.456);
    font-size: 25px;
  }

  @media screen and (max-width: $breakpoint-3) {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 10px;
    pointer-events: all;
    z-index: 9999;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.sidebar {
  overflow: hidden;
  height: 100vh;
  width: 250px;
  min-width: 250px;
  color: #747b95;
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "body"
    "footer";
  background: #fff;
  border-right: 1px solid rgb(242, 242, 242);

  @media screen and (max-width: $breakpoint-3) {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 250px;
    left: 0;
    bottom: 0;
    transition: 0.2s;
    transform: translateX(-100%);

    &.menu-shown {
      z-index: 21;
      transform: translateX(0);
    }
  }

  .top {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    .back-link {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #949494;
      text-decoration: none;
      transition: 0.2s;

      .icon {
        font-size: 14px;
      }

      &:hover {
        color: #616161;
      }
    }

    .project-details {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-top: 20px;

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        background: #fafafc;
        border-radius: 50em;
      }

      .content {
        color: #000000;

        h4 {
          margin-bottom: 10px;
          font-weight: 400;
          font-size: 18px;
        }

        p {
          font-weight: 300;
          font-size: 14px;
          margin: 0;

          span {
            font-weight: 400;
          }
        }
      }
    }
  }

  &.developer {
    background: #0c0f21;
  }

  * {
    max-width: 100%;
  }

  .sidebar-header {
    grid-area: header;
    padding: 30px 25px 0;
    max-width: 100%;
  }

  .sidebar-body {
    grid-area: body;
    overflow: auto;

    display: flex;
    flex-direction: column;
    padding: 0 30px;
    margin-top: 30px;

    .divider {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      margin: 15px 0;
    }

    button.upgrade {
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 50px;
      background-color: var(--colorDarkGraphPrimary);
      color: var(--colorPureWhite);
      border: none;

      .custom-svg {
        margin-top: -2px;
      }

      &:hover {
        background-color: var(--colorActive);
      }
    }

    a {
      color: #747b95;
      text-decoration: none;
      transition: all 0.2s;
      font-weight: 400;
      display: flex;
      align-items: flex-start;
      gap: 10px;
      padding: 15px 0;
      margin: 0;
      border-left: none;

      .custom-svg {
        rect,
        path {
          transition: all 0.2s;
          fill: #747b95;
        }
      }

      &:hover {
        color: #6881e9;

        .custom-svg {
          rect,
          path {
            fill: #6881e9;
          }
        }
      }

      &.active {
        color: #6881e9;
        border-left: none;
        cursor: default;
        pointer-events: none;

        .custom-svg {
          rect,
          path {
            fill: #6881e9;
          }
        }
      }
    }
  }

  .sidebar-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    grid-area: footer;
    max-width: 100%;
    height: 75px;
    margin: 0 20px;

    &.developer {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    .developer-menu {
      border-top: none;
      background-color: transparent;
    }
  }
}
