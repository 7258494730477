.checkbox-wrapper {
	display: flex;
	align-items: center;
	gap: 10px;

	input[type='checkbox'] {
		position: relative;
		width: 20px;
		height: 20px;
		color: #0c0f21;
		border: 1px solid #babec1;
		border-radius: 2px;
		appearance: none;
		padding: 7px 10px;
		outline: 0;
		cursor: pointer;
		transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
		&::before {
			position: absolute;
			content: '';
			display: block;
			top: 2px;
			left: 7px;
			width: 3px;
			height: 10px;
			border-style: solid;
			border-color: #fff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			opacity: 0;
		}
		&:checked {
			color: #fff;
			border-color: #6881e9;
			background: #6881e9;
			&::before {
				opacity: 1;
			}
			~ label::before {
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
			}
		}
	}

	label {
		position: relative;
		font-family: 'Lato';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		color: #0c0f21;

		@media screen and (max-width: 500px) {
			font-size: 12px;
		}
	}
}
