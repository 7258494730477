.webhook-messages {
  .data-table-wrapper {
    flex: 1;
  }
  .webhook-dropdown {
    position: relative;
    .open {
      cursor: pointer;
      font-family: inherit;
      padding: 4px 15px;
      margin-bottom: 2px;
    }
    .menu {
      background: white;
      position: absolute;
      top: 100%;
      right: 0;
      button {
        padding: 4px 15px;
        margin-bottom: 2px;
        cursor: pointer;
        width: 100%;
        font-family: inherit;
      }
    }
  }
  .webhook-messages-load-more {
    text-align: center;
  }
  .now-viewing {
    background: rgba(0, 0, 0, 0.025);
  }

  .app-content-table {
    .load-more {
      text-align: center;
      span {
        transition: color 200ms;
        padding: 6px;
        margin-top: 12px;
        font-weight: 400;
        cursor: pointer;
        display: inline-block;
        font-size: 0.9em;
        color: rgba(0, 0, 0, 0.55);
        letter-spacing: 0.5px;
        // &:after {
        //   content: '';
        //   width: 0px;
        //   height: 1px;
        //   display: block;
        //   background: rgba(0, 0, 0, 0.25);
        //   transition: 150ms ease-out;
        // }
        // &:hover:after {
        //   width: 100%;
        // }
        &:hover {
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
  .data-table-wrapper {
    margin-bottom: 0;
  }
  .webhook-messages-table {
    margin-bottom: 40px;
  }
}
