.app-info {
	width: 100%;
	margin-bottom: 30px;

	.top {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 20px;

		h3 {
			margin-bottom: 20px;
			font-weight: 400;
			font-size: 20px;
		}

		.buttons-container {
			display: flex;
			align-items: center;
			gap: 10px;
			justify-content: flex-end;
			margin: 0 0 0 auto;
		}
	}

	.content {
		display: flex;
		gap: 100px;

		@media screen and (max-width: 1000px) {
			gap: 20px;
			justify-content: space-between;
		}

		@media screen and (max-width: 700px) {
			gap: 10px;
			flex-direction: column;
		}

		.box {
			display: flex;
			flex-direction: column;
			gap: 10px;

			@media screen and (max-width: 860px) {
				width: 100%;
			}

			.info-wrapper {
				display: flex;
				gap: 30px;

				p {
					font-weight: 300;
					color: #0c0f21;
					font-size: 14px;
					margin: 0;

					&.info-title {
						min-width: 105px;
						font-weight: 400;
					}

					&.tag {
						padding: 3px 10px;
						font-weight: 500;
						font-size: 12px;
						background-color: #000;
						color: white;
						border-radius: 20px;

						&.blue {
							background-color: #6881e9;
						}
						&.green {
							background-color: #30bb57;
						}
					}
				}
			}
		}
	}
}
