@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_reset';
@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

* {
	box-sizing: border-box;
}

body {
	background: transparent;
	color: $black-20;
	font-size: 14px;
	line-height: 1.5em;
	font-weight: 300;
	font-family: 'Lato', sans-serif;
	height: 100%;
	position: relative;
}

// For embedded views
.app {
	> .user-menu-wrapper,
	> .open-login {
		display: none;
	}
}

#header {
	line-height: 1em;
	font-weight: normal;
	padding: 10px 20px;
	box-sizing: border-box;

	a {
		text-decoration: none;
	}

	.open-login {
		color: #fff;
	}

	nav {
		position: relative;
		display: flex;
		min-height: 45px;
	}

	.website-menu-toggler.opened {
		svg {
			display: none;
		}
	}

	#logo img {
		max-height: 35px;
		margin-bottom: -5px;

		@media screen and (max-width: $breakpoint-3) {
			max-height: 30px;
		}
	}
}

#main-content {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	font-family: 'Poppins', sans-serif;

	@media screen and (max-width: $breakpoint-3) {
		flex-direction: column;
	}
}

.main-menu {
	a {
		&.active {
			border-left-color: $pink-10;

			@media screen and (max-width: $breakpoint-3) {
				background-color: $pink-10;
			}
		}

		svg,
		i {
			width: 20px !important;
			margin-right: 15px;
			text-align: center;

			@media screen and (max-width: $breakpoint-3) {
				margin-right: 0;
			}
		}
	}
}

.main-section {
	flex: 1;
	min-height: calc(100vh - 160px);
	padding: 50px;
	background: #fafafc;

	> header {
		background: #fafafc;
	}

	@media screen and (max-width: $breakpoint-4) {
		padding: 20px;
		min-height: calc(100vh - 56px);
	}
}

@import 'common';
