.data-table-card {
	width: 100%;
	margin-bottom: 30px;
	min-height: 250px;
	background-color: white;
	border: 1px solid #F0F0F0;
	border-radius: 10px;
	padding: 20px 30px;

	@media screen and (max-width: 400px) {
		padding: 20px;
	}

	input:not([type='checkbox']) {
		font-size: 14px;
		font-weight: 500;
		border-radius: 5px;
		border: 1px solid #babec1;
		background-color: transparent;
		outline: none;
		color: #0c0f21;
		min-width: 208px;

		&::placeholder {
			color: #babec1;
		}

		&:focus {
			border: 1px solid #747b95;
		}
	}

	.filter-modal-container {
		position: relative;
		.filter-icon {
			cursor: pointer;
			position: absolute;
			z-index: 12;
			right: -20px;
			top: 55px;
			color: #989898;
			font-size: 18px;
			transition: 0.2s;
			&:hover {
				color: #8b8b8b;
			}

			@media screen and (max-width: 1282px) {
				right: 10px;
				top: 0px;
			}
		}

		.filter-modal {
			&.opened {
				opacity: 1;
				pointer-events: all;
			}
			opacity: 0;
			transition: 0.2s;
			pointer-events: none;
			width: fit-content;
			max-height: 250px;
			overflow-y: scroll;
			position: absolute;
			right: -10px;
			top: calc(100% + 75px);
			z-index: 110;
			background-color: #fff;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
			border-radius: 10px;
			padding: 15px;

			display: flex;
			flex-direction: column;
			gap: 18px;

			@media screen and (max-width: 1282px) {
				right: 10px;
				top: calc(100% + 25px);
			}

			.row {
				display: flex;
				align-items: flex-start;
				gap: 9px;

				p {
					font-weight: 300;
					font-size: 14px;
					color: #141414;
					margin-bottom: 0;
				}
			}
		}
	}

	.data-table-wrapper {
		margin-bottom: 0;
		.table-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			flex-wrap: wrap;
			gap: 10px;

			.title-wrapper {
				display: flex;
				align-items: center;
				gap: 10px;

				p {
					margin: 0;
					font-weight: 300;
					font-size: 14px;
					color: #1e2a3b;
					align-self: flex-end;
					padding-bottom: 3px;
				}
			}

			h3 {
				font-weight: 400;
				font-size: 20px;
				color: #0c0f21;
				margin: 0;
			}

			.filter-wrapper {
				display: flex;
				align-items: center;
				gap: 15px;
				flex-wrap: wrap;

				.refresh-btn {
					cursor: pointer;
					font-size: 22px;
					color: #b1b1b1;
					transition: 0.2s;

					&.active {
						transform: rotate(720deg);
					}

					&:hover {
						color: #8b8b8b;
					}
				}

				.search-form {
					display: flex;
					align-items: flex-end;

					.input-container {
						margin-bottom: 0;
					}

					input[type='text'] {
						border-right: none;
						border-radius: 5px 0 0 5px;

						&:focus {
							border: 1px solid #b4b4b4;
							border-right: none;
						}
					}

					*:last-child {
						border-radius: 0 5px 5px 0;
					}
				}

				.primary-button {
					display: flex;
					gap: 10px;
					font-weight: 500;
					font-size: 14px;
					min-width: fit-content;
				}

				.icon {
					color: #babec1;
					font-size: 20px;
					cursor: pointer;
					transition: 0.2s;

					&:hover {
						color: #6881e9;

						circle {
							transition: 0.2s;
							stroke: #6881e9;
						}
					}
				}

				.search-wrapper {
					position: relative;

					.icon {
						position: absolute;
						left: 13px;
						top: 5.5px;
						color: #babec1;
						font-size: 18px;
					}
				}
			}
		}

		.inner-table-wrapper {
			overflow-x: auto;
			max-width: 100%;

			.data-table {
				border-collapse: collapse;
				border-radius: 0;
				width: 100%;
				font-family: 'Lato', sans-serif;
				box-shadow: none;

				&:not(.default) {
					td {
						cursor: pointer;
					}
				}

				td {
					padding: 10px;
					border: none;
					height: fit-content;
					
					img{
						width: 60px;
						height: 60px;
						object-fit: cover;
						border-radius: 2px;
					}

					p {
						display: flex;
						gap: 10px;
						align-items: center;
						color: #0c0f21;
						font-weight: 300;
						font-size: 14px;
						margin: 0;

						&.status {
							padding: 1px 10px;
							width: fit-content;
							font-weight: 500;
							font-size: 12px;
							color: white;
							border-radius: 20px;
							background-color: #999;

							&.paying {
								background-color: #6881e9;
							}
							&.active,
							&.approved,
							&.completed {
								background-color: #30bb57;
							}
							&.inactive,
							&.deleted,
							&.failed,
							&.draft {
								background-color: #e54657;
							}
						}
					}
				}

				tr {
					border-bottom: 1px solid #14141413;
					position: relative;

					&:nth-last-child(1) {
						border-bottom: none;
					}

					.actions-container {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 10px;
						right: 10px;
						top: 50%;

						.icon {
							color: #c4c4c4;
							font-size: 16px;
							transition: 0.2s;
							cursor: pointer;

							&:hover {
								color: #8b8b8b;
							}

							&.duplicate {
								font-size: 19px;
							}

							&.refund,
							&.invoice {
								font-size: 22px;
							}

							&.edit {
								font-size: 17px;
							}
						}
					}
				}

				th {
					border-left: none;
					border-bottom: 2px solid #14141413;
					padding: 10px 0 5px 10px;
					text-align: left;
					color: #0c0f21;
					font-weight: 600;
					font-size: 14px;

					p {
						margin: 0;
						display: flex;
						align-items: center;
						gap: 5px;
						cursor: pointer;
						width: fit-content;

						.arrow {
							opacity: 0;
							transition: 0.2s;
							font-size: 16px;
							color: #747b95;

							&.desc {
								opacity: 1;
								transform: rotate(180deg);
							}

							&.asc {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		.loading-wrapper {
			height: 165px;
			display: flex;
			align-items: center;
			justify-content: center;

			.d-loader {
				position: relative;
				transform: unset;
				left: unset;
				top: unset;
			}
		}

		.no-items-container {
			margin-top: 50px;
			display: flex;
			gap: 25px;
			justify-content: center;
			align-items: center;
			width: 100%;
			flex-direction: column;
			text-align: center;

			p {
				font-size: 18px;
				max-width: 300px;
				line-height: normal;
				font-weight: 300;
			}
		}

		.bottom-wrapper {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin-top: 20px;
			position: relative;

			select {
				left: 0;
				position: absolute;
			}

			.data-pagination {
				justify-self: center;
				justify-content: center;
			}
		}
	}
	select {
		padding: 6px 10px;
		font-weight: 300;
		font-size: 14px;
		border-radius: 5px;
		border-color: #b4b4b4;
		color: #1e2a3b;
	}
}
