@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.projects {
	position: relative;
	font-family: 'Poppins', sans-serif;

	> .inner, .cn-announcements {
		max-width: 1200px;
	}

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		@media screen and (max-width: $breakpoint-3) {
			flex-direction: column;
			padding-bottom: 40px;
		}
	}

	.no-results {
		background-color: white;
		padding: 50px 20px;
		border-radius: 10px;
		border: 1px solid #F0F0F0;
		max-width: 1200px;
		width: 100%;

		p {
			font-weight: 300;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			color: #141414;
			margin-top: 30px;
			padding-left: 20px;
		}

		.buttons-wrapper {
			margin: 40px 0;
		}
	}

	.search-components {
		.input-container {
			input,
			select {
				min-height: 33px;
			}

			input {
				padding-left: 40px;
				background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34938 1.60176C7.21356 -0.533921 3.73735 -0.533921 1.60152 1.60176C-0.53384 3.73791 -0.53384 7.21343 1.60152 9.34958C3.50353 11.251 6.46581 11.4547 8.60025 9.96996C8.64516 10.1825 8.74795 10.3852 8.91324 10.5505L12.0237 13.6608C12.477 14.1131 13.2094 14.1131 13.6604 13.6608C14.1132 13.208 14.1132 12.4756 13.6604 12.0242L10.5499 8.913C10.3856 8.7491 10.1823 8.64586 9.96981 8.60095C11.4556 6.46619 11.2519 3.50457 9.34938 1.60176ZM8.36736 8.36761C6.77278 9.96209 4.17766 9.96209 2.58355 8.36761C0.989897 6.77314 0.989897 4.17866 2.58355 2.58419C4.17766 0.990178 6.77278 0.990178 8.36736 2.58419C9.96194 4.17866 9.96194 6.77314 8.36736 8.36761Z' fill='%23B4B4B4'/%3E%3C/svg%3E%0A");
				background-position: 15px 50%;
				background-repeat: no-repeat;
			}
		}
	}

	.projects-wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(320px, 320px));
		grid-gap: 30px;
		margin-top: 10px;

		&.less-than-2 {
			grid-template-columns: repeat(auto-fit, minmax(300px, 570px));

			@media (max-width: $breakpoint-3) {
				grid-template-columns: 1fr;
			}

			.project {
				.top {
					flex-direction: row;
					gap: 30px;

					img.thumbnail {
						width: 85px;
						height: 85px;
					}
				}
			}
		}

		@media (max-width: $breakpoint-3) {
			grid-template-columns: 1fr;
		}

		.project {
			padding: 30px;
			border-radius: 10px;
			background-color: #fff;
			border: 1px solid #F0F0F0;
			min-height: 150px;
			flex: 1;
			max-width: 670px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&.create {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					button {
						color: #536ed8;
					}
				}

				button {
					font-weight: 500;
					transition: 0.2s;
					font-size: 16px;
					color: #6881e9;
					width: 60px;
					height: 60px;
					position: relative;
					border-radius: 50em;
					background: #FAFAFE;
					margin-top: -20px;

					&::after {
						content: 'Create a New Project';
						position: absolute;
						left: 50%;
						top: 100%;
						transform: translateX(-50%);
						width: 220px;
						margin-top: 5px;
					}
				}
			}

			.manage-project {
				align-self: flex-end;
				color: #6881e9;
				display: flex;
				align-items: center;
				gap: 5px;
				border: 1px solid transparent;
				font-weight: 700;
				padding: 0;
				margin-top: 5px;
			}

			.top {
				display: flex;
				flex-direction: column;
				gap: 10px;

				img.thumbnail {
					width: 60px;
					height: 60px;
					border-radius: 50em;
					background: #fafafc;
					object-fit: cover;
					cursor: pointer;
				}

				.details {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding-top: 5px;
					min-height: 85px;
					height: fit-content;

					.avatar-group-wrapper {
						span {
							width: 30px;
							height: 30px;
						}
					}

					.title-wrapper {
						margin-bottom: 6px;

						h3 {
							cursor: pointer;
							margin-bottom: 0;
							font-weight: 700;
							font-size: 18px;
							line-height: 22px;
							color: #1E2A3B;
							transition: 0.2s;

							&:hover {
								color: #536ed8;
							}
						}

						p {
							margin-top: 5px;
							font-size: 12px;
						}
					}

					p {
						margin: 0;
					}
				}
			}
		}
	}

	.cn-announcements {
		padding: 30px 0 0;
		border-top: 1px solid #00000015;
		margin-top: 270px;
	}
}
