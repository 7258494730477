.plan-features {
  .data-table {
    * {
      vertical-align: middle;
    }
    .input-container {
      margin-bottom: 0;
      max-width: 150px;
      input {
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
