.developer-page .developer-account {
  padding: 140px 0 80px;

  &.wrapper {
    width: 800px;
  }

  > header.flex-wrapper {
    position: sticky;
    top: 0;
    background: var(--backgroundMain);
    padding: 20px 0;
    z-index: 3;
    margin-bottom: 30px;
  }
}

.app-dashboard {
  &.no-wrap {
    flex-wrap: nowrap;
  }
  .developer-account {
    &.wrapper {
      width: auto;
      max-width: inherit;
      padding: 0;
      margin-top: -20px;
    }

    header.flex-wrapper {
      background: #f5f5f5;
    }
  }
}
