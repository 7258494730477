@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';
// New dashboard colors:
:root {
	--backgroundMenu: #0c0f21;
	--backgroundSubmenuPrimary: #4d5265;
	--backgroundSubmenuSecondary: #3c4156;
	--backgroundTopbar: #1e2a3b;
	--backgroundMain: #f6f6fa;
	--colorLabelPurple: #b046f1;
	--colorDividerLightGrey: #f3f3f3;
	--colorDividerGrey: #dddddd;
	--colorDarkGraphPrimary: #6881e9;
	--colorDarkGraphPrimaryActive: #2c49bd;
	--colorDarkGraphSecondary: #747886;
	--colorLightGraphPrimary: #c6ceef;
	--colorLightGraphSecondary: #c2d6fb;
	--colorIconGrey: #7f888e;
	--colorPureWhite: #ffffff;
	--colorWarning: #f55959;
	--colorWarningHover: #d43c3c;
	--colorActive: #3b54bc;
	--shadowPrimary: 0px 0px 10px rgba(0, 0, 0, 0.08);
}

.developer-page {
  min-height: 100vh;
  background-color: var(--backgroundMain);
  font-family: Lato;
  line-height: 1.5em;

  .wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
  }

  .flex-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  hgroup {
    margin-bottom: 30px;

    h2 {
      margin-bottom: 10px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3em;
      margin: 10px 0 0;
      color: #1e2a3b;

      &.trim {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 24px;
        white-space: nowrap;
        max-width: 800px;
      }
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  h2 {
    font-family: Spartan;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    font-weight: 500;
    line-height: 1.5em;
    color: #1e2a3b;
  }

  h3 {
    font-family: Spartan;
    font-weight: 300;
    font-size: 22px;
    line-height: 1.5em;
  }

  em,
  .highlight {
    color: var(--colorDarkGraphPrimary);
  }

  .app-section {
    background-color: var(--colorPureWhite);
    box-shadow: var(--shadowPrimary);
    box-sizing: border-box;
    border-radius: 10px;
    max-width: 930px;
    margin-bottom: 30px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .app-section-header {
    justify-content: space-between;
    align-items: center;
    margin: 0 13px 15px;
    padding: 20px 17px 20px;
    background-color: var(--colorPureWhite);
    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 500;

      span {
        font-size: 13px;
        font-family: monospace;
        margin-left: 7px;
      }
    }
  }
  .app-section-header:not(.separator-light) + .app-section-body {
    padding-top: 0;
  }

  .app-section-body {
    font-family: Lato;
    transition: all 0.3s ease;
    padding: 20px 30px;
    // background-color: #fcfcfd;

    @media screen and (max-width: $breakpoint-4) {
      padding: 20px;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    .form-row {
      @media screen and (max-width: $breakpoint-3) {
        flex-direction: row;
      }
    }

    .info-box {
      align-items: center;
      gap: 7px;
      font-size: 16px;
      margin-top: 40px;
      transition: color 0.2s;

      &:hover {
        color: var(--colorDarkGraphPrimary);
      }

      a {
        color: #000;
        transition: color 0.2s;

        &:hover {
          color: var(--colorDarkGraphPrimary);
        }
      }
    }

    ul {
      font-size: 16px;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 20px;
        align-items: flex-start;
        gap: 10px;

        @media screen and (max-width: 600px) {
          flex-direction: column;
        }

        .status {
          display: inline;
          margin-right: 8px;

          .fa-check {
            color: #20bf55;
          }
        }

        h5 {
          font-weight: 600;
          flex: 1;

          span {
            font-weight: 300;
            margin-top: 5px;
            display: block;
            font-size: 14px;
          }
        }

        .button {
          padding: 8px 15px 4px;
          width: 200px;
          position: relative;
          .external-link-icon {
            position: absolute;
            right: 10px;
          }
        }
      }
    }
  }

  .use-credentials-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    gap: 20px;
    margin: 20px 0;
  }

  .credential-box {
    flex: 1;
    background: rgba(255, 255, 255, 0.5);
    padding: 15px;
    border-radius: 5px;
    border: 1px solid rgba(9, 13, 38, 0.5);
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;

    &:hover {
      background: rgba(255, 255, 255, 0.75);
    }

    svg {
      position: absolute;
      top: 10px;
      right: 10px;
      color: var(--colorDarkGraphPrimary);
      font-size: 18px;
    }

    h6 {
      font-weight: 600;
      margin: 0 0 10px;
    }

    p {
      margin: 0;
    }

    &.active {
      border-color: var(--colorDarkGraphPrimary);
      box-shadow: inset 0 0 0 1px var(--colorDarkGraphPrimary);
    }
  }

  .delete-app {
    border: 1px solid var(--colorWarning);
    border-radius: 10px;
    padding: 20px 30px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      color: var(--colorWarning);
    }
    display: flex;
    flex-direction: column;
    .button {
      background-color: var(--colorWarning);
      margin-left: auto;
      align-self: flex-end;
      color: var(--colorPureWhite);
      &:hover {
        background-color: var(--colorWarningHover);
      }
    }
  }

  .button.dashboard-button {
    color: var(--colorDarkGraphPrimary);
    font-family: 'lato';
    border: 2px solid var(--colorDarkGraphPrimary);
    border-radius: 50px;
    min-width: 200px;
    transition: color 200ms, border-color 200ms;

    &:hover {
      color: var(--colorDarkGraphPrimaryActive);
      border-color: var(--colorDarkGraphPrimaryActive);
    }
  }
  .button.dashboard-button {
    padding: 5px 25px;
    &.grey {
      color: var(--colorIconGrey);
      border-color: var(--colorIconGrey);
    }
    &:hover,
    &.active {
      color: var(--colorDarkGraphPrimaryActive);
      border-color: var(--colorDarkGraphPrimaryActive);
    }
  }

  .link-button {
    background: none;
    border: none;
    color: var(--colorDarkGraphPrimary);
    font-weight: 300;

    &:hover {
      // text-decoration: underline;
      color: var(--colorActive);
    }
  }

  .number-input.disabled {
    pointer-events: none;
    + .buttons-container {
      display: none;
    }
  }
  .number-input + .buttons-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 33px;
    i {
      position: absolute;
    }
  }
  .buttons-container {
    i {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 5L11 1' stroke='%23B4B4B4' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-position: center center;
      background-repeat: no-repeat;
      width: 8px;
      height: 8px;
      left: 50%;
      transform: rotate(180deg);
      top: 5px;

      cursor: pointer;
      + i {
        transform: translateY(15px);
      }
      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &:after {
        position: absolute;
        content: '';
        inset: 0;
        opacity: 0;
        border-radius: 50%;
        opacity: 0.25;
        transform: scale(1.65);
        transition: background-color 0.15s ease;
        background-color: transparent;
      }
      &:hover {
        &:after {
          background-color: var(--colorIconGrey);
          opacity: 0.3;
        }
      }
    }
  }

  .inputs-horizontal {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    .input-container {
      flex: 1;
      label {
        display: inline-block;
        margin-right: 20px;
      }
      input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .buttons-container {
        bottom: 5px;
      }
      &.small {
        input, select {
          max-width: 70px;
        }
      }

      &.medium {
        input, select {
          max-width: 200px;
        }
      }
    }
  }

  .separator {
    border: none;
    border-bottom: 1px solid rgb(20, 20, 20, 30%);
  }
  .separator-light {
    border-bottom: 1px solid var(--colorDividerLightGrey);
  }

  .form-row {
    font-family: Lato;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &.column {
      label {
        flex-basis: 0;
        width: auto;
        padding: 0 5px;
      }
    }

    label {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      color: #090d26;
      text-transform: none;
      width: 200px;
      flex-basis: 200px;

      @media screen and (max-width: $breakpoint-3) {
        flex-basis: 120px;
      }

      + p {
        margin: 0;
        font-weight: 300;
        font-size: 20px;
        color: #090d26;

        &.flex-wrapper {
          gap: 5px;
        }

        .button {
          padding: 4px 0;
        }
      }
    }

    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    textarea,
    select {
      background-color: #fcfcfd;
      border: 1px solid rgba(9, 13, 38, 0.5);
      border-radius: 4px;
      font-family: Lato;
      font-weight: 300;
      font-size: 16px;
      color: #090d26;

      &:focus {
        border-color: var(--colorDarkGraphPrimary);
        box-shadow: inset 0 0 0 1px var(--colorDarkGraphPrimary);
      }

      &:disabled {
        background-color: #dadadd;
        border-color: #dadadd;
        box-shadow: none;
      }
    }

    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    textarea {
      &:read-only {
        background-color: #dadadd;
        border-color: #dadadd;
        box-shadow: none;
        padding-right: 60px;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .align-center {
    align-items: center;
  }

  .width-100 {
    width: 100%;
  }

  .align-end {
    align-items: flex-end;
  }

  .space-between {
    justify-content: space-between;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .caution {
    color: $pink-10;
  }

  .app-content-wrapper {
    max-width: 930px;
  }

  .app-content-table {
    flex: 1;
    table {
      background: transparent;
      width: 100%;
      box-shadow: none;

      thead {
        background: transparent;

        th {
          border-left: none;
          padding: 10px 12px;
          text-align: left;
          font-weight: 600;
        }

        color: rgba(0, 0, 0, 0.45);
        font-weight: 500;
        border-bottom: 1px solid #dce0e9;
        border-bottom: none;
      }
      tbody {
        tr {
          border-top: 1px solid rgba(0, 0, 0, 0.06);

          td {
            border-left: none;
            padding: 10px 12px 8px;
          }
        }
      }
    }
  }
  .app-editor-page {
    .app-editor-page-back {
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #949494;
      transition: all 0.2s ease;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        color: #94949480;
      }

      .chevron-left {
        border-style: solid;
        border-width: 1px 1px 0 0;
        height: 9px;
        position: relative;
        width: 9px;
        transform: rotate(-135deg);
      }
    }
    h2,
    h3 {
      font-family: 'Lato', Arial, Helvetica, sans-serif;
      font-weight: 400;
      margin-bottom: 5px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    hgroup {
      margin-bottom: 20px;
    }
  }
  .webhooks-page {
    overflow: scroll;
    .data-table-wrapper {
      margin-bottom: 0;
    }
    th:last-child {
      color: transparent;
    }
    .row-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      span:first-child {
        margin-right: 5px;
      }
      .webhook-button {
        width: 28px;
        height: 28px;
        cursor: pointer;

        svg {
          color: #d3d3d3;
          transition: color 0.15s ease;
        }
        &:hover svg {
          color: var(--colorActive);
        }
      }
    }
  }

  .permissions-table {
    .data-table-wrapper {
      margin: 0;
    }
    table {
      box-shadow: none;
    }
    th,
    td {
      border: none;
      vertical-align: middle;
    }
    th:nth-child(3),
    th:nth-child(4) {
      text-align: center;
    }
    tr:not(:last-child) {
      border-bottom: 1px solid #dce0e9;
    }
    .toggle {
      text-align: center;
    }
  }

  i.no-wrap {
    display: inline-block;
  }
  .meter-indicator {
    width: 150px;
    height: 4px;
    background-color: var(--colorLightGraphSecondary);
    border-radius: 10px;
    margin: 10px 0 12px;
    position: relative;
    overflow: hidden;

    .meter-indicator-value {
      position: absolute;
      background-color: var(--colorDarkGraphPrimary);
      height: 100%;
      animation: fromLeft 1s;
      @keyframes fromLeft {
        0% {
          transform: translateX(-120%);
          opacity: 0.5;
        }
      }
    }
  }

  .meter-label {
    font-family: $mainFont;
    .meter-label-value {
      font-size: 18px;
      font-weight: 500;
    }

    .meter-label-max-value {
      margin-left: 5px;
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}

.popup {
	background: none;

	.popup-content {
		background: #ebecec;
		border: 2px solid rgba(9, 13, 38, 0.05);
		box-sizing: border-box;
		border-radius: 12px;
		width: 800px;
		margin-bottom: 40px;
		position: relative;

		h2 {
			text-align: left;
			font-size: 22px;
			font-weight: 500;
			color: var(--colorDarkGraphPrimary);
			padding: 15px 30px 12px;
			background: #fcfcfd;
		}

		> section {
			padding: 38px 40px 30px;
			background: #f2f2f4;
		}

		.form-row {
			margin-bottom: 20px;
		}

		.buttons-wrapper {
			padding: 20px 0 0;

			&.flex-wrapper {
				justify-content: flex-end;
			}
		}
	}
	&.popup-warn .popup-content {
		width: 480px;
		padding: 25px 30px;
		background-color: #fff;

		header {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 10px;

			&.tabs {
				gap: 0;
			}

			svg {
				width: 26px;
				height: 26px;
			}
			h3 {
				font-family: 'Lato';
				font-weight: 500;
				margin-bottom: 2px;
				font-size: 16px;
			}
		}
		p {
			font-size: 14px;
		}
		.popup-warn-buttons {
			.secondary-button {
				border-color: var(--colorDarkGraphPrimary);
			}
			display: flex;
			margin-top: 20px;
			justify-content: flex-end;
			gap: 20px;
		}
	}
}
