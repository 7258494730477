@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.widget-catalog {
	position: relative;
	font-family: 'Poppins', sans-serif;

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.main-content-wrapper {
		margin-top: 30px;
		display: flex;
    align-items: flex-start;
		gap: 30px;
		max-width: 1300px;

		@media (max-width: 655px) {
			flex-direction: column;
		}

		.filter-container {
			min-width: 220px;
			display: flex;
			flex-direction: column;
			gap: 15px;
			position: sticky;
			top: 30px;
			height: fit-content;
			max-height: 70vh;
			overflow: auto;

			@media (max-width: 655px) {
				position: static;
			}

			.search-components {
				margin-bottom: -20px;
				position: relative;

				.reset {
					position: absolute;
					top: 6px;
					right: 5px;
					color: inherit;
					cursor: pointer;
					color: gray;
				}

				input {
					padding-left: 40px;
					padding-right: 25px;
					position: relative;
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34938 1.60176C7.21356 -0.533921 3.73735 -0.533921 1.60152 1.60176C-0.53384 3.73791 -0.53384 7.21343 1.60152 9.34958C3.50353 11.251 6.46581 11.4547 8.60025 9.96996C8.64516 10.1825 8.74795 10.3852 8.91324 10.5505L12.0237 13.6608C12.477 14.1131 13.2094 14.1131 13.6604 13.6608C14.1132 13.208 14.1132 12.4756 13.6604 12.0242L10.5499 8.913C10.3856 8.7491 10.1823 8.64586 9.96981 8.60095C11.4556 6.46619 11.2519 3.50457 9.34938 1.60176ZM8.36736 8.36761C6.77278 9.96209 4.17766 9.96209 2.58355 8.36761C0.989897 6.77314 0.989897 4.17866 2.58355 2.58419C4.17766 0.990178 6.77278 0.990178 8.36736 2.58419C9.96194 4.17866 9.96194 6.77314 8.36736 8.36761Z' fill='%23B4B4B4'/%3E%3C/svg%3E%0A");
					background-position: 15px 50%;
					background-repeat: no-repeat;
				}
			}

			.categories {
				padding-left: 10px;
				display: flex;
				flex-direction: column;
				gap: 15px;

				.cat-title {
					font-weight: 500;
					font-size: 14px;
					color: #000000;
					margin: 0;
				}

				.divider {
					border-top: 1px solid rgba(0, 0, 0, 0.15);
					margin: -5px 0;
				}

				.cat {
					display: flex;
					align-items: center;
					color: #747b95;
					gap: 10px;
					border: none;
					background: transparent;
					font-weight: 300;
					font-size: 14px;
					line-height: 17px;
					cursor: pointer;
					transition: 0.2s;

					.icon {
						font-size: 18px;
					}

					&.active,
					&:hover {
						color: #6881e9;
					}
				}
			}
		}

		.widget-catalog-home {
			display: flex;
			flex-direction: column;
			gap: 100px;

			h3 {
				font-size: 22px;
				font-weight: 600;
				display: flex;
				align-items: center;
				gap: 5px;
				margin: 0 0 30px;

				svg {
					color: #747B95;
				}
			}
		}

		.apps-container {
			gap: 30px;
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			
			&:has(.no-items) {
				align-items: center;
				width: 100%;
			}

			.no-items {
				min-width: 100%;
				display: flex;
				flex-direction: column;
				gap: 30px;
				align-items: center;
				margin: 0 auto;

				p {
					font-size: 16px;
					padding-left: 20px;
				}

				img {
					max-width: 120px;
				}
			}

			.app {
				background: #FAFAFC;
				border: 1px solid #EEEEEE;
				border-radius: 5px;
				cursor: pointer;
				position: relative;
				display: flex;
				flex-direction: column;
				flex: 1;
				min-width: 280px;
				max-width: 280px;

				&:hover {
					.plugin-listing-actions {
						opacity: 1;

						&:before {
							opacity: 0.92;
							z-index: 0;
						}
					}
				}

				.plugin-listing-actions {
					opacity: 0;
					transition: opacity 0.2s ease-in-out;

					.action {
						cursor: pointer;
						position: absolute;
						inset: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						color: var(--colorDarkGraphPrimary);
						text-decoration: none;
						padding: 0 20px;

						svg {
							margin-right: 10px;
						}

						span {
							text-align: center;
							font-size: 18px;
							font-weight: 500;
						}
					}

					&:before {
						transition: opacity 0.2s ease-in-out;
						border-radius: 5px;
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-color: var(--colorPureWhite);
					}
				}

				img {
					width: 100%;
					height: 170px;
					object-fit: cover;
					border-radius: 5px 5px 0 0;
				}

				.details {
					display: flex;
					flex-direction: column;
					gap: 5px;
					color: #1e2a3b;
					padding: 20px;
					min-height: 120px;

					h4 {
						font-weight: 600;
						font-size: 16px;
						line-height: 1.5;
						margin: 0;
					}

					p {
						margin: 0;
						font-weight: 300;
						font-size: 14px;
						line-height: 1.5;
					}
				}
			}
		}
	}
}
