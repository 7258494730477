@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.developer-menu {
	position: absolute;
	z-index: 4;

	&.user-dashboard {
		color: black;

		&.in-menu {
			// border-top: 1px solid rgba(0, 0, 0, 0.15);

			.user-menu-wrapper {
				.user-menu {
					background: white;
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

					a {
						color: black;

						&:hover {
							background-color: rgb(245, 245, 245);
						}
					}
				}
			}
		}
	}

	&:not(.in-menu) {
		max-width: 250px;
		inset: 0;
		margin-left: auto;
		height: 86px;

		.thumbnail {
			position: absolute;
			top: 20px;
			right: 50px;
			cursor: pointer;
		}
		// }

		.user-menu-wrapper.opened {
			.user-menu {
				top: calc(100%);
				height: fit-content;
			}
		}
	}

	&.in-menu {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		bottom: 0;
		left: 0;
		border-top: 1px solid #ffffff20;

		.developer-menu-content {
			.user-menu-wrapper {
				&.opened {
					.user-menu {
						bottom: calc(100% - 10px);
						left: 10px;
						top: auto;
					}
				}
				.user-menu-toggler {
					padding: 18px;
					cursor: pointer;
					display: flex;
					align-items: center;
					// width: 100%;

					span:nth-child(2) {
						// title
						display: block;
						font-size: 14px;
						padding: 0 15px;
						max-width: 180px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
	}

	.developer-menu-content {
		// position: relative;
		.user-menu-wrapper {
			// position: relative;
			&.opened {
				.user-menu {
					display: flex;
				}
			}
			&.closed {
				.user-menu {
					display: none;
				}
			}
			.user-menu-toggler {
				.thumbnail {
					width: 38px;
					height: 38px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100%;
					border-radius: 50%;
				}
				span:nth-child(2) {
					display: none; // title
				}
				svg {
					display: none;
				}
			}
			.user-menu {
				z-index: 1;
				border-radius: 10px;
				background: #1e293b;
				position: absolute;
				left: 0;
				width: 200px;
				flex-direction: column;
				overflow: hidden;
				top: 100%;
				padding: 10px 0;
				svg {
					width: 30px;
					margin-right: 10px;
					text-align: right;
					display: none;
				}

				a {
					color: var(--colorPureWhite);
					padding: 7px 20px;
					text-decoration: none;
					user-select: none;
					position: relative;
					margin: 0;
					font-size: 12px;

					&:after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-color: var(--colorPureWhite);
						opacity: 0;
					}
					&:hover:after {
						opacity: 0.05;
					}
				}
			}
		}
	}
}
