.database-container {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	max-width: 930px;

	.creation-card {
		padding: 40px 30px;
		background-color: #ffffff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 600px;
		flex: 270px;

		@media screen and (max-width: 985px) {
			max-width: 100%;
		}

		.logo {
			margin: auto;
		}

		p {
			text-align: center;
			margin: 30px auto;
			font-size: 16px;
			line-height: normal;
			max-width: 450px;
			font-size: 18px;
		}

		button {
			max-width: 160px;
			margin: auto;
		}
	}
}
