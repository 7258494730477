.password {
  div.ReactPasswordStrength {
    width: auto;
    .ReactPasswordStrength-strength-bar {
      top: 0;
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
      max-width: calc(100% - 3px);
      left: 1.5px;
      height: 2px;
    }
    .ReactPasswordStrength-strength-desc {
      transform: translateY(-35px);
      font-family: Lato;
      letter-spacing: 0.024em;
      font-weight: normal;
    }
  }
}
