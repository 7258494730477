@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.profile {
  .user-email-status {
    font-style: italic;
    color: #028d20;
    font-weight: 500;

    &.unconfirmed {
      color: #830f0f;
    }
  }
  .inner-wrapper {
    display: flex;
    gap: 54px;

    justify-content: flex-start;
    align-items: flex-start;
    > div:nth-child(2) {
      flex: 1;
    }
    .email-updates {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  #upload-input {
    display: none;
  }

  .thumbnail-upload {
    border-radius: 50em;
    width: 150px;
    height: 150px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
    position: relative;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-3) {
      margin-top: 10px;
    }

    .thumbnail {
      border-radius: 50em;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: url('../../assets/default_thumb.png') no-repeat center center;
      background-size: cover;
      z-index: 1;
    }

    .upload-button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      border-radius: 50em;
      background: rgba(0, 0, 0, 0.85);
      opacity: 0;
      transition: opacity 0.3s ease;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 24px;
      color: #fff;

      &:hover {
        opacity: 1;
      }
    }
  }

  .reset-botton {
    background: none;
    border: none;
    cursor: pointer;
    color: #999;
    text-align: center;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
    font-size: 16px;
    transition: color 0.3s ease;

    &:hover {
      color: #333;
    }
  }

  .danger-zone {
    max-width: 930px;
    margin-top: 40px;
  }
}
