.collection-details {
	.subtitle {
		margin-top: 10px;
		display: flex;
		gap: 20px;
		align-items: center;

		.copy-wrapper {
			p {
				font-weight: 300;
				font-size: 14px;
				color: black;
				margin: 0;
				position: relative;
				display: flex;
				align-items: center;
				gap: 10px;
				padding-right: 20px;
				z-index: 10;

				word-break: break-word;

				@media screen and (max-width: 460px) {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					width: 180px;
				}
			}
		}

		h4 {
			font-size: 14px;
			font-weight: 400;
			margin: 0;
		}
	}
}
