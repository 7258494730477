@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.app-submenu {
  border-radius: 5px;
  color: #fff;
  position: relative;
  background: var(--backgroundSubmenuPrimary);
  margin-top: 30px;

  .submenu-toggler {
    cursor: pointer;
  }

  &.open {
    .submenu-list {
      height: content-fit;
    }
    .submenu-icon-wrapper {
      transform: translate(-23px, -50%) rotate(0);
    }
  }
  .selected-app {
    user-select: none;
    padding: 12px 20px 10px;
    h3 {
      max-width: calc(100% - 35px);
      margin-bottom: 0;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 580px) {
        font-size: 12px;
        line-height: 1.9;
      }
    }
  }
  .submenu-icon-wrapper {
    position: absolute;
    width: 15px;
    // bottom: 0;
    // width: 100%;
    // height: 50px;

    // .submenu-icon:before {
    content: url("data:image/svg+xml,%3Csvg width='22' height='10' viewBox='0 0 22 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 9L11.625 1L1 9' stroke='white'/%3E%3C/svg%3E%0A");
    right: 0;
    top: 50%;
    transform: translate(-23px, -50%) rotate(-180deg);

    // transform: translate(-20px, -50%);
    transition: transform 0.2s ease;
    // }
  }

  .submenu-list {
    position: absolute;
    top: 100%;
    margin-top: 10px;
    left: 0;
    right: 0;
    z-index: 20;
    background-color: var(--backgroundTopbar);
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .submenu-item,
    .submenu-add-item {
      order: 1;
      cursor: pointer;
      position: relative;

      &.selected {
        position: relative;
        pointer-events: none;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: 20px;
          right: 20px;
          bottom: 0;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5899 2.34785L6.20434 9.59727C5.65743 10.1342 4.77025 10.1342 4.22283 9.59727L0.410339 5.85454C-0.13678 5.31757 -0.13678 4.44659 0.410339 3.90952C0.957561 3.37234 1.84467 3.37234 2.39165 3.90931L5.21388 6.67974L11.6083 0.402726C12.1555 -0.134445 13.0427 -0.134039 13.5897 0.402726C14.1367 0.939796 14.1367 1.81047 13.5899 2.34785Z' fill='%236881E9'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
        }
      }
      &:after {
        z-index: -1;
        content: '';
        border-radius: 5px;
        display: block;
        position: absolute;
        top: 5px;
        left: 10px;
        right: 10px;
        bottom: 5px;
        background-color: var(--backgroundSubmenuPrimary);
        opacity: 0;
        transition: opacity 0.15s ease;
      }
      &:hover {
        span,
        a {
          color: var(--colorPureWhite);
        }
        &:after {
          opacity: 0.5;
        }
      }
      &:hover:after {
        opacity: 0.5;
      }
    }
    a,
    span {
      font-size: 12px;
      user-select: none;
      padding: 12px 20px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      color: var(--colorPureWhite);
    }
    .submenu-add-item {
      position: sticky;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: var(--backgroundTopbar);
      margin: 0 10px;
      border-top: 1px solid #ffffff20;

      span {
        margin: 0 10px;
      }

      &:after {
        left: 0;
        right: 0;
      }

      &:before {
        content: '+';
        color: var(--colorDividerGrey);
        font-weight: 300;
        font-size: 20px;
        position: absolute;
        top: 50%;
        transform: translate(10px, -50%);
        left: 0;
        transition: inherit;
      }
    }
  }
}
