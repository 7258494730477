.plan-editor {
  .plan-editor-header {
    display: flex;
    justify-content: space-between;

    .input-container {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 5px;
      label {
        margin-top: 2px;
      }
    }
  }
  .inputs-horizontal {
    .input-container.plan-name {
      input {
        max-width: 250px;
      }
    }
  }
}
.active-button {
  vertical-align: middle;
  padding: 2px;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    .switch {
      .slider {
        background-color: #bdbcbc;
      }
    }
  }

  .switch {
    display: inline-block;
    font-size: 20px;
    width: 50px;
    border: 2px solid transparent;
    border-radius: 1em;
    outline: none;
    background-color: #d3d3d3;

    .slider {
      border-radius: 1em;
      background-color: #fff;
      transition: all 0.2s ease-in-out;
    }
    &.active {
      background-color: var(--colorDarkGraphPrimary);
      .slider {
        background-color: #fff;
        transform: translate3d(82%, 0, 0);
      }
      .switch {
        background-color: #d3d3d3;
      }
    }
  }
}
