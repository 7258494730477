.media-manager-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: #FAFAFC;
  padding: 15px 20px;
  border-radius: 10px 10px 0 0;
  position: sticky;
  top: 0;
  z-index: 1;

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      font-size: 1.2rem;
    }
  }

  .search {
    position: relative;
   
    .input-container {
      margin: 0;

      input {
        padding-left: 30px;
  
        &:disabled + svg {
          opacity: 0.3;
        }
      }
    }

    svg {
      font-size: 1.2rem;
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}