.field-types-popup {
	.popup-content {
		textarea {
			resize: none;
			height: 85px;
		}
	}

	.settings-container {
		display: flex;
		gap: 30px;
		flex-wrap: wrap;

		.checkbox-field {
			display: flex;
			flex-direction: column;
			gap: 5px;
			flex: 170px;

			.number-container {
				margin: 0 0 0 auto;
				height: 35px;

				.number-select {
					width: 50px;
				}
			}

			.title-wrapper {
				display: flex;
				gap: 10px;
				align-items: center;
			}
		}

		h4 {
			margin: 0;
			font-weight: 500;
			font-size: 14px;
		}

		p {
			margin: 0;
			line-height: normal;
			font-weight: 300;
			font-size: 12px;
			max-width: 255px;
		}
	}

	.box-select-container {
		display: flex;
		gap: 30px;
		flex-wrap: wrap;

		.box {
			cursor: pointer;
			border: 1px solid #b4b4b4;
			color: #141414;
			padding: 20px;
			border-radius: 5px;
			flex: 200px;
			transition: 0.2s;
			position: relative;

			.checked {
				position: absolute;
				right: 10px;
				font-size: 20px;
				top: 10px;
				color: #6881e9;
				opacity: 0;
			}

			&:hover {
				border: 1px solid #6881e9;
			}

			h4 {
				margin: 0 0 7px;
				font-weight: 600;
				font-size: 14px;
			}

			p {
				margin: 0;
				font-weight: 300;
				font-size: 14px;
			}

			&.active {
				border: 1px solid #6881e9;

				.checked {
					opacity: 1;
				}
			}
		}
	}

	.fields-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 20px;

		.field {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 20px;
			color: #141414;
			border: 1px solid #b4b4b4;
			border-radius: 5px;
			transition: 0.2s;
			padding: 10px 20px;
			width: 255px;
			max-width: 255px;
			flex: 190px;

			@media screen and (max-width: 510px) {
				max-width: 100%;
			}

			&:hover {
				border: 1px solid #6881e9;
			}

			h4 {
				margin: 0;
				font-weight: 500;
				font-size: 14px;
			}

			p {
				margin: 0;
				line-height: normal;
				font-weight: 300;
				font-size: 12px;
				max-width: 160px;
			}
		}
	}
}
