.plans {
  .plans-container {
    h6 {
      font-weight: 600;
      margin: 0 5px 0 0;
      display: inline-block;
    }
  }
  .app-card-header .title {
    max-width: 110px;
  }
}
