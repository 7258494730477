@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.project-permissions {
	position: relative;

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		max-width: 930px;
		margin-bottom: 30px;
		gap: 10px;

		.filter-wrapper {
			display: flex;
			align-items: flex-start;
			gap: 10px;
			margin-bottom: -30px;
			margin-left: auto;

			input {
				padding-left: 40px;
				background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34938 1.60176C7.21356 -0.533921 3.73735 -0.533921 1.60152 1.60176C-0.53384 3.73791 -0.53384 7.21343 1.60152 9.34958C3.50353 11.251 6.46581 11.4547 8.60025 9.96996C8.64516 10.1825 8.74795 10.3852 8.91324 10.5505L12.0237 13.6608C12.477 14.1131 13.2094 14.1131 13.6604 13.6608C14.1132 13.208 14.1132 12.4756 13.6604 12.0242L10.5499 8.913C10.3856 8.7491 10.1823 8.64586 9.96981 8.60095C11.4556 6.46619 11.2519 3.50457 9.34938 1.60176ZM8.36736 8.36761C6.77278 9.96209 4.17766 9.96209 2.58355 8.36761C0.989897 6.77314 0.989897 4.17866 2.58355 2.58419C4.17766 0.990178 6.77278 0.990178 8.36736 2.58419C9.96194 4.17866 9.96194 6.77314 8.36736 8.36761Z' fill='%23B4B4B4'/%3E%3C/svg%3E%0A");
				background-position: 15px 50%;
				background-repeat: no-repeat;
			}
		}

		@media (max-width: $breakpoint-3) {
			max-width: 100%;
		}

		.page-titles {
			padding-bottom: 0px;
		}
	}

	.permissions-data-table {
		box-shadow: none;
		padding: 15px;
		max-width: 930px;
	}

	.no-access-box {
		max-width: 930px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 30px;
		background: #ffffff;
		border-radius: 10px;
		padding: 73px 20px;

		p {
			font-weight: 300;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			max-width: 300px;
		}
	}
}
