@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

aside.developer-app-menu {
	font-family: 'spartan';
	background: var(--backgroundMenu);
	width: 250px;
	color: #fff;
	padding: 30px 20px;
	min-height: 100vh;

	&.dashboard {
		background-color: white;
		nav {
			
			button.upgrade {
				font-weight: 500;
				font-size: 18px;
				margin: 0 10px;
				display: flex;
				align-items: center;
				gap: 10px;
			}

			section {
				padding-left: 10px;
				a {
					color: #747b95;
					text-decoration: none;
					transition: all 0.2s;
					font-weight: 400;
					display: flex;
					align-items: center;
					gap: 10px;
					padding: 15px 0;
					margin: 0;
					border-left: none;

					rect {
						fill: #747b95;
					}

					&:hover {
						color: #6881e9;
					}

					&.active {
						color: #6881e9;
						border-left: none;
						cursor: default;
						pointer-events: none;

						rect,
						path {
							fill: #6881e9;
						}
					}
				}
			}
		}
	}

	.nav-content {
		top: 30px;
		position: sticky;
	}

	.logo {
		text-align: center;

		a {
			justify-content: center;
			outline: none;
			text-decoration: none;

			p {
				display: none;
			}
		}
	}
	@media screen and (max-width: $breakpoint-3) {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		bottom: 0;
		transition: 0.2s;
		transform: translateX(-100%);

		&.menu-shown {
			z-index: 21;
			transform: translateX(0);
		}
	}

	hgroup {
		margin-top: 0;
		margin-bottom: 20px;
		padding: 15px 0 30px;
		border-bottom: 1px solid #717380;

		h3 {
			color: #eeeeee;
			margin-bottom: 10px;
			display: flex;
			align-items: flex-start;

			img {
				width: 30px;
				height: 30px;
				margin-right: 10px;
				border-radius: 3px;
				box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
			}

			+ p {
				color: #a9abba;
				font-size: 16px;
				font-family: Lato;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		div.flex-wrapper {
			margin: 10px 0 0;
			justify-content: flex-end;
			text-transform: uppercase;
		}
	}

	a {
		color: #f5f5f5;
		text-decoration: none;
		transition: all 0.2s;

		&:hover {
			color: var(--colorDarkGraphPrimary);
		}
	}

	nav {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		&.user-dashboard {
			height: calc(100vh - 45px);
		}

		&:not(.user-dashboard) {
			height: calc(100vh - 110px);
		}

		.navigation-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			grid-gap: 30px;
			gap: 30px;
			padding: 20px 0;
			height: calc(100% - 100px);
			margin-top: 5px;
			overflow-y: auto;
		}

		@media screen and (max-width: $breakpoint-3) {
			position: unset;
		}

		section {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			flex-wrap: wrap;
			padding-left: 20px;
			h5 {
				color: var(--colorIconGrey);
				margin: 0 0 20px;
				font-size: 12px;
				font-weight: 400;
			}
			&:first-child {
				padding-bottom: 20px;
				border-bottom: 1px solid #ffffff20;
			}
		}

		a {
			font-size: 14px;
			padding: 6px 0 3px 10px;
			margin-bottom: 15px;

			&:last-of-type {
				margin-bottom: 0;
			}
			border-left: 2px solid transparent;

			&.active {
				color: var(--colorDarkGraphPrimary);
				border-left: 2px solid var(--colorDarkGraphPrimary);
				cursor: default;
				pointer-events: none;
			}
		}
	}

	.back-btn {
		font-size: 16px;
		margin-bottom: 10px;
		display: inline-block;

		svg {
			margin-right: 5px;
		}
	}
}
