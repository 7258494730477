@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';
.popup.project-upgrade {
	.popup-content {
		width: 600px;
		text-align: center;

		img {
			max-width: 100%;
		}
		header {
			justify-content: center;
			margin: 0;
			h3 {
				text-align: center;
				font-weight: 500;
				font-size: 20px;
				margin-bottom: 20px;
			}
		}

		.popup-warn-text {
			margin: 0;
		}

		.popup-warn-buttons {
			justify-content: center;
			margin-top: 50px;
			button {
				padding: 9px 48px;
				gap: 10px;
				font-size: 18px;
			}
		}
	}
}
