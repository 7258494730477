@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.center {
	text-align: center;
}

i {
	font-style: italic;
}

strong {
	font-weight: 700;
}

:root {
	--ninja-color: #{$black-20};
}

.capitalize {
	text-transform: capitalize;
}

.page-titles {
	padding-bottom: 20px;

	h2 {
		margin: 0;
		font-weight: 600;
		font-size: 28px;
		line-height: 1.5;
		color: #1E2A3B;

		@media screen and (max-width: $breakpoint-3) {
			font-size: 20px;
		}
	}

	@media screen and (max-width: $breakpoint-3) {
		text-align: center;
	}

	h3 {
		font-size: 14px;
		margin: 10px 3px 0;
	}
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	@media screen and (max-width: $breakpoint-3) {
		position: relative;
		margin: 40px auto;
		left: 0;
		top: 0;
		transform: none;
		text-align: center;
	}
}

// Tooltips

.local-tooltip {
	position: absolute;
	left: 50%;
	top: 100%;
	transform: translateX(-50%);
	background: $black-20;
	color: #fff;
	z-index: 5;
	padding: 10px;
	border-radius: 3px;
	width: 230px;
	max-width: 90%;
	margin-top: 15px;
	line-height: 1.5em;
	display: none;

	&::before {
		content: '';
		position: absolute;
		left: 50%;
		bottom: 100%;
		transform: translateX(-50%);
		border: 8px solid transparent;
		border-bottom-color: $black-20;
	}
}

// Forms

.form-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;

	@media screen and (max-width: $breakpoint-3) {
		flex-direction: column;
		align-items: flex-start;
		text-align: left;
	}

	::placeholder {
		color: #cecece;
	}

	> label {
		width: 140px;
		flex-basis: 140px;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 700;
		color: $gray-20;
		letter-spacing: 0.025em;

		@media screen and (max-width: $breakpoint-3) {
			width: 100%;
			flex-basis: 0;
		}
	}

	.form-elm {
		flex: 1;

		&.hosted {
			max-height: 40px;

			*[data-bluesnap] {
				height: 40px;
				max-width: 320px;
			}

			iframe {
				float: none !important;
			}
		}

		@media screen and (max-width: $breakpoint-3) {
			width: 100%;

			&.hosted {
				*[data-bluesnap] {
					max-width: 100%;
				}
			}
		}
	}

	input[type='text'],
	input[type='password'],
	input[type='email'],
	input[type='number'],
	textarea,
	select {
		padding: 10px;
		border: 1px solid #dce0e9;
		border-radius: 3px;
		font-size: 14px;
		background: #fff;
		font-family: $secondaryFont;
		color: #aab0be;
		font-weight: 300;

		&::placeholder {
			color: #aab0be;
		}

		&:focus {
			color: #5c606b;
			border-color: $black-20;
		}
	}

	select {
		appearance: listbox;
		-webkit-appearance: listbox;
	}
}

.btn {
	font-size: 14px;
	color: rgba(0, 0, 0, 0.5);
	padding: 10px 30px;
	background: none;
	font-weight: 300;
	border-radius: 3px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	cursor: pointer;
	font-family: $secondaryFont;
	transition: all 0.2s ease;
	text-decoration: none;

	&:hover {
		border: 1px solid rgba(0, 0, 0, 0.5);
		color: rgba(0, 0, 0, 0.8);
	}

	&:disabled {
		opacity: 0.3;
	}

	&.major {
		border-color: $black-20;
		background: $black-20;
		color: #fff;

		&:hover {
			color: #fff;
			background: #111214;
		}
	}

	&.red {
		border-color: #830f0f;
		background: #830f0f;
		color: #fff;

		&:hover {
			background: #941313;
		}
	}

	&.green {
		border-color: #34830f;
		background: #34830f;
		color: #fff;

		&:hover {
			background: #28660c;
		}
	}
}

.input-container {
	margin-bottom: 20px;
	position: relative;

	.input-select {
		cursor: pointer;
	}

	label {
		display: block;
		font-family: 'Lato', Arial, sans-serif;
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 10px;
	}

	textarea,
	input,
	select {
		width: 100%;
		font-family: 'Lato', Arial, sans-serif;
		font-size: 14px;
		padding: 7px 15px;
		color: #141414;
		font-weight: 300;
		border-radius: 5px;
		border: 1px solid #b4b4b4;

		&:placeholder {
			color: #b4b4b4;
		}

		&:disabled {
			opacity: 0.5;
			background-color: rgba(0, 0, 0, 0.07);
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 5L11 1' stroke='%23B4B4B4' stroke-linecap='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		padding-right: 40px;
		background-position: right 15px top 50%;
		text-transform: capitalize;
	}

	textarea {
		resize: vertical;
		height: 150px;
	}
	small {
		display: block;
		font-size: 12px;
		color: var(--colorDarkGraphSecondary);
		font-weight: 500;
	}
	.copier {
		padding-right: 15px;
		position: relative;
		font-size: 0;
		border: 1px solid #b4b4b4;
		border-radius: 5px;

		&.light {
			background: rgba(0, 0, 0, 0.07);
		}

		input,
		textarea {
			border: 0;
			font-size: 14px;
			color: #090d26;
			text-overflow: ellipsis;
			background: none;
		}

		&:after {
			content: '';
			display: block;
			cursor: pointer;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 30px;
			transition: background-image 0.2s ease;
			background-position: top 8px center;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.695652' y='3.47837' width='8.34782' height='11.8261' rx='0.695652' stroke='%234C4FFF' stroke-width='1.3913'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.1742 1.3913H11.4145L11.4145 11.1318H11.3869V12.5231H11.4145C12.1829 12.5231 12.8058 11.9002 12.8058 11.1318V1.3913C12.8058 0.622908 12.1829 0 11.4145 0H4.1742C3.40581 0 2.7829 0.622908 2.7829 1.3913V1.39145H4.1742V1.3913Z' fill='%234C4FFF'/%3E%3C/svg%3E");
		}
		&:hover:after {
			background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.695652' y='3.47837' width='8.34782' height='11.8261' rx='0.695652' stroke='%233B54BC' stroke-width='1.3913'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.1742 1.3913H11.4145L11.4145 11.1318H11.3869V12.5231H11.4145C12.1829 12.5231 12.8058 11.9002 12.8058 11.1318V1.3913C12.8058 0.622908 12.1829 0 11.4145 0H4.1742C3.40581 0 2.7829 0.622908 2.7829 1.3913V1.39145H4.1742V1.3913Z' fill='%233B54BC'/%3E%3C/svg%3E");
		}
	}
}

.data-table-wrapper {
	width: 100%;
	overflow-x: auto;
	margin-bottom: 40px;
	max-width: 1400px;
	padding: 20px 30px;

	@media screen and (max-width: $breakpoint-3) {
		max-width: 100%;
	}
}

.data-table {
	empty-cells: show;
	min-width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border-radius: 10px;
	margin-bottom: 5px;
	box-shadow: none;
	font-family: Lato, sans-serif;
	color: #141414;

	thead {
		background-color: $white-10;
		border-radius: 10px 10px 0 0;
		color: #333;
		font-weight: 600;
		text-align: left;
		vertical-align: bottom;
		border-bottom: 1px solid #14141430;
	}

	th,
	td {
		border-width: none;
		border-left: none;
		border-width: none;
		font-size: inherit;
		margin: 0;
		overflow: visible;
		padding: 10px;
		font-weight: 300;
		font-size: 14px;

		&.status {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}

	th {
		padding-bottom: 10px;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
	}

	tbody tr {
		transition: all 0.2s ease;
		&:not(:last-child) {
			border-bottom: 1px solid #14141405;
		}
		&:hover {
			border-bottom: none;
			box-shadow: none;
			&:not(:last-child) {
				border-bottom: 1px solid #14141405;
			}
		}
	}

	.actions {
		a {
			margin: 0 5px;
		}
	}

	a {
		color: #4C4FFF;
		text-decoration: none;
		transition: color 0.2s ease;
		font-weight: 500;
		font-size: 14px;

		&:hover {
			color: #5670d6;
			text-decoration: underline;
		}
	}
}

.account,
.billing,
.project-settings,
.media-manager,
.widgets,
.project-permissions,
.developer-page,
.dashboard,
.popup-content,
.discounts,
.new-apps,
.projects,
.app-dashboard {
	.tabs-wrapper {
		max-width: 1400px;

		.permissions-buttons-container {
			display: flex;
			flex-direction: column;
			gap: 5px;
			padding: 5px 0;

			.permission-button {
				cursor: pointer;
				display: flex;
				align-items: flex-start;

				.switch {
					cursor: pointer;
					pointer-events: none;
					display: inline-block;
					font-size: 20px; /* 1 */
					height: 22px;
					width: 2em;
					background: #f5f5f5;

					&.active {
						background: var(--colorDarkGraphPrimary);
					}
					border: 1px solid rgba(0, 0, 0, 0.091);
					border-radius: 1em;

					& input {
						cursor: pointer;
						position: absolute;
						opacity: 0;
					}

					& div {
						height: 1em;
						width: 1em;
						border-radius: 1em;
						background: #fff;
						box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
							rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
						border: 1px solid rgba(0, 0, 0, 0.043);
						-webkit-transition: all 300ms;
						-moz-transition: all 300ms;
						transition: all 300ms;
					}

					& input:checked {
						& + div {
							-webkit-transform: translate3d(90%, 0, 0);
							-moz-transform: translate3d(90%, 0, 0);
							transform: translate3d(90%, 0, 0);
						}
					}
				}

				p {
					min-width: 60px;
					margin: 0;
				}
			}
		}

		.tabs {
			text-align: left;
			padding: 0;
			margin-bottom: 40px;
			display: inline-block;

			.tab-trigger {
				font-size: 16px;
				margin: 0;
				padding: 10px 20px;
				border: none;
				display: inline-block;
				border-bottom: 1px solid var(--colorIconGrey);
				color: var(--colorIconGrey);
				font-weight: 300;
				transition: color 0.2 ease;
				position: relative;

				&:after {
					display: block;
					content: attr(title);
					font-weight: 400;
					height: 0;
					overflow: hidden;
					visibility: hidden;
				}

				&:hover {
					color: var(--colorDarkGraphPrimary);
					border-bottom-color: var(--colorDarkGraphPrimary);
				}

				&.active {
					cursor: default;
					background: none;
					font-weight: 400;
					color: var(--colorDarkGraphPrimary);
					pointer-events: none;
				}

				&.active:before {
					content: '';
					position: absolute;
					left: 0;
					bottom: -1.5px;
					right: 0;
					width: 100%;
					height: 2px;
					border-radius: 10px;
					background-color: var(--colorDarkGraphPrimary);
					transition: background-color 0.2s ease;
				}
			}
		}

		.active-tab {
			position: relative;
			width: 100%;
			min-height: 150px;
			font-size: 16px;
		}
	}

	.button {
		padding: 5px 20px;
		border: none;
		font-family: 'Lato';
		font-weight: 500;
		font-size: 14px;
		line-height: 1.5em;
		// text-transform: capitalize;
		border: 1px solid transparent;
		cursor: pointer;
		border-radius: 5px;
		transition: color 0.1s ease, border-color 0.05s ease,
			background-color 0.2s ease;
		text-decoration: none;

		&.loading {
			pointer-events: none;
			opacity: 0.6;
		}

		&.w-100 {
			min-width: 100px;
		}
	}

	.primary-button {
		background-color: var(--colorDarkGraphPrimary);
		color: var(--colorPureWhite);

		&:hover {
			background-color: var(--colorActive);
		}
	}

	.secondary-button {
		background-color: none;
		color: var(--colorDarkGraphPrimary);
		border-color: var(--colorDarkGraphPrimary);
		border-radius: 5px;
		font-weight: 300;

		&:hover {
			background: var(--colorActive);
			border-color: var(--colorActive);
			color: var(--colorPureWhite);
		}
	}

	.horizontal {
		.input-container {
			> *:not(label) {
				display: inline-block;
				width: calc(100% - 150px);
			}
			label {
				display: inline-block;
				width: 150px;
				vertical-align: top;
			}

			.image-upload {
				display: inline-block;
			}
		}
	}

	.image-upload {
		border-radius: 6px;
		width: 150px;
		height: 150px;
		position: relative;
		cursor: pointer;

		&:hover .image {
			background-image: url("data:image/svg+xml,%3Csvg width='103' height='103' viewBox='0 0 103 103' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='101' height='101' rx='9' stroke='%23cdcdcd' stroke-width='2' stroke-linecap='round' stroke-dasharray='5 8'/%3E%3Cpath d='M54.4044 52.1726C54.8091 51.7678 54.8091 51.1115 54.4044 50.7067L52.3313 48.6337C52.1369 48.4393 51.8732 48.3301 51.5983 48.3301C51.3235 48.3301 51.0598 48.4393 50.8654 48.6337L48.7923 50.7067C48.3876 51.1115 48.3876 51.7678 48.7923 52.1726C49.1971 52.5774 49.8534 52.5774 50.2582 52.1726L50.5618 51.869V55.5858C50.5618 56.1583 51.0259 56.6224 51.5983 56.6224C52.1708 56.6224 52.6349 56.1583 52.6349 55.5858V51.869L52.9385 52.1726C53.3433 52.5774 53.9996 52.5774 54.4044 52.1726Z' fill='%23cdcdcd'/%3E%3Cpath d='M46.415 58.6967C46.415 58.1242 46.8791 57.6602 47.4516 57.6602H55.7439C56.3163 57.6602 56.7804 58.1242 56.7804 58.6967C56.7804 59.2692 56.3163 59.7332 55.7439 59.7332H47.4516C46.8791 59.7332 46.415 59.2692 46.415 58.6967Z' fill='%23cdcdcd'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M43.3059 36.9277C41.5885 36.9277 40.1963 38.32 40.1963 40.0373V62.8411C40.1963 64.5585 41.5885 65.9507 43.3059 65.9507H59.8905C61.6079 65.9507 63.0001 64.5585 63.0001 62.8411V44.1835C63.0001 43.9086 62.8908 43.6449 62.6965 43.4505L56.4772 37.2313C56.2829 37.0369 56.0192 36.9277 55.7443 36.9277H43.3059ZM42.2694 40.0373C42.2694 39.4649 42.7334 39.0008 43.3059 39.0008H54.7078V44.1835C54.7078 44.7559 55.1718 45.22 55.7443 45.22H60.927V62.8411C60.927 63.4136 60.4629 63.8776 59.8905 63.8776H43.3059C42.7334 63.8776 42.2694 63.4136 42.2694 62.8411V40.0373ZM59.4611 43.1469L56.7808 40.4667V43.1469H59.4611Z' fill='%23cdcdcd'/%3E%3C/svg%3E%0A");
		}
		@media screen and (max-width: $breakpoint-3) {
			margin-top: 10px;
		}

		.image {
			border-radius: 6px;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transition: background-image 0.2s ease;
			background-image: url("data:image/svg+xml,%3Csvg width='103' height='103' viewBox='0 0 103 103' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='101' height='101' rx='9' stroke='%23B4B4B4' stroke-width='2' stroke-linecap='round' stroke-dasharray='5 8'/%3E%3Cpath d='M54.4044 52.1726C54.8091 51.7678 54.8091 51.1115 54.4044 50.7067L52.3313 48.6337C52.1369 48.4393 51.8732 48.3301 51.5983 48.3301C51.3235 48.3301 51.0598 48.4393 50.8654 48.6337L48.7923 50.7067C48.3876 51.1115 48.3876 51.7678 48.7923 52.1726C49.1971 52.5774 49.8534 52.5774 50.2582 52.1726L50.5618 51.869V55.5858C50.5618 56.1583 51.0259 56.6224 51.5983 56.6224C52.1708 56.6224 52.6349 56.1583 52.6349 55.5858V51.869L52.9385 52.1726C53.3433 52.5774 53.9996 52.5774 54.4044 52.1726Z' fill='%23B4B4B4'/%3E%3Cpath d='M46.415 58.6967C46.415 58.1242 46.8791 57.6602 47.4516 57.6602H55.7439C56.3163 57.6602 56.7804 58.1242 56.7804 58.6967C56.7804 59.2692 56.3163 59.7332 55.7439 59.7332H47.4516C46.8791 59.7332 46.415 59.2692 46.415 58.6967Z' fill='%23B4B4B4'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M43.3059 36.9277C41.5885 36.9277 40.1963 38.32 40.1963 40.0373V62.8411C40.1963 64.5585 41.5885 65.9507 43.3059 65.9507H59.8905C61.6079 65.9507 63.0001 64.5585 63.0001 62.8411V44.1835C63.0001 43.9086 62.8908 43.6449 62.6965 43.4505L56.4772 37.2313C56.2829 37.0369 56.0192 36.9277 55.7443 36.9277H43.3059ZM42.2694 40.0373C42.2694 39.4649 42.7334 39.0008 43.3059 39.0008H54.7078V44.1835C54.7078 44.7559 55.1718 45.22 55.7443 45.22H60.927V62.8411C60.927 63.4136 60.4629 63.8776 59.8905 63.8776H43.3059C42.7334 63.8776 42.2694 63.4136 42.2694 62.8411V40.0373ZM59.4611 43.1469L56.7808 40.4667V43.1469H59.4611Z' fill='%23B4B4B4'/%3E%3C/svg%3E%0A");
			background-size: cover;
			z-index: 1;
		}

		.image-upload-button {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			border-radius: 6px;
			opacity: 0;
			transition: all 0.3s ease;
			background: rgba(0, 0, 0, 0.05);
			border: none;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			font-size: 24px;
			color: #fff;
			transform: none;
		}

		.reset-botton {
			background: none;
			border: none;
			cursor: pointer;
			color: #999;
			text-align: center;
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
			margin-left: 10px;
			font-size: 16px;
			transition: color 0.3s ease;

			&:hover {
				color: #333;
			}
		}
	}

	.options-menu {
		position: relative;
		display: inline-block;

		.options-menu-button {
			width: 25px;
			height: 25px;
			display: inline-block;
			color: var(--colorDarkGraphPrimary);
			transition: color 0.2s ease;
			position: relative;
			text-align: center;
			cursor: pointer;
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			transform: rotate(90deg);

			&:after {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				background-color: var(--colorIconGrey);
				opacity: 0;
				transition: opacity 0.2s ease;
				border-radius: 50%;
			}
			&:hover {
				color: var(--colorActive);
			}
			&:hover:after {
				opacity: 0.3;
			}
			.options-menu-button-icon {
				transform: scale(0.5);
			}
		}
		.options-menu-list {
			position: absolute;
			background: white;
			min-width: 160px;
			top: 100%;
			right: 5px;
			z-index: 2;
			padding: 10px 0;
			display: flex;
			flex-direction: column;

			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
			border-radius: 10px;

			.options-menu-item {
				font-weight: 300;
				font-size: 14px;
				display: flex;
				align-items: center;
				gap: 10px;
				padding: 5px 23px;
				cursor: pointer;
				transition: background-color 120ms;
				color: #141414;

				.icon {
					color: #949494;
				}

				&.delete {
					color: #e54657;
					.icon {
						color: #e54657;
					}
				}

				&:hover {
					background-color: rgba(0, 0, 0, 0.023);
				}
			}
		}
	}

	.copier.copied:before,
	.copier .copied:before {
		letter-spacing: normal;
		font-size: 14px;
		position: absolute;
		background: var(--backgroundMenu);
		color: var(--colorPureWhite);
		content: 'Copied';
		margin: 0 auto;
		bottom: calc(100% + 5px);
		width: fit-content;
		left: 0;
		right: 20px;
		padding: 6px 8px 4px;
		border-radius: 3px;
		animation: fadeInBottom 900ms both;
	}
	.light .copied:before,
	.light.copied:before {
		background: #fff;
		color: #949494;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
	}

	@keyframes fadeInBottom {
		from {
			transform: translateY(40%) scale(0.95);
		}
		20% {
			transform: translateY(0);
		}
		80% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}

.content-wrapper {
	max-width: 930px;
	background-color: var(--colorPureWhite);
	border: 1px solid #F0F0F0;
	border-radius: 10px;

	@media screen and (max-width: $breakpoint-3) {
		padding: 0 20px;
		text-align: center;
	}
	.buttons-wrapper {
		text-align: end;
	}
}
