.custom-plan {
  margin: 30px 0 10px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  font-family: inherit;
  color: var(--lead-text);

  a {
    color: var(--lead-blue);
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}