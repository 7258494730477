.app-dashboard .integrations-page .tabs-wrapper {
  max-width: 930px;
}

.developer-page .app-section.app-integration {
  flex-basis: 290px;
  padding: 30px 20px 18px;
  text-align: center;
  margin: 0;
  background-color: var(--colorPureWhite);
  border: none;
  box-shadow: var(--shadowPrimary);

  .status {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
  }

  p {
    font-size: 16px;
    margin: 20px 0;
  }

  img {
    max-width: 100%;
    height: 30px;
    margin: 0;
    &.shift4shop {
      height: 25px;
    }
  }

  .buttons-wrapper {
    margin: 30px 0 0;

    .button {
      width: 100px;
    }
  }
}
