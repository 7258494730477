.stats-card {
	color: #0c0f21;
	flex: 1;
	min-width: 285px;
	max-width: 505px;

	h3 {
		margin-bottom: 20px;
		font-weight: 400;
		font-size: 20px;

		@media (max-width: 500px) {
			font-size: 18px;
		}
	}

	.content {
		display: flex;
		align-items: center;
		gap: 30px;

		.stat-icon {
			min-width: 50px;
		}

		div {
			overflow: unset;
		}

		.stats-container {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;

			.stat {
				display: flex;
				gap: 7px;
				justify-content: space-between;
				flex-direction: column;

				p {
					font-weight: 400;
					margin: 0;
					color: #0c0f21;
					font-weight: 400;
					font-size: 20px;

					&.stat-title {
						font-weight: 300;
						font-size: 14px;
						min-width: 53px;
					}
				}
			}
		}
	}
}
