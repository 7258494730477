@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.auth-page {
	$white-bg: #ffff;
	$font-color: #050505;

	position: relative;
	width: 100vw;
	min-height: 100vh;
	background: $white-bg;
	color: $font-color;
	font-family: Poppins, sans-serif;
	display: flex;
	align-items: stretch;
	justify-content: center;

	&.embedded {
		min-height: 700px;
		height: 100vh;
	}

	@media screen and (max-width: $breakpoint-3) {
		flex-direction: column;
	}

	&.feedback {
		.feedback-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			@media screen and (max-width: $breakpoint-1) {
				margin-top: 0;
			}

			h3 {
				font-size: 25px;
				font-weight: 500;
				line-height: normal;
				&.submited {
					margin-top: 250px;
					margin-bottom: 0;
				}
			}

			p {
				line-height: 1.5;
				margin-top: 5px;
				font-size: 18px;
			}

			.reasons-cards-container {
				display: flex;
				justify-content: center;
				margin-top: 10px;
				gap: 20px;
				flex-wrap: wrap;

				@media screen and (max-width: $breakpoint-1) {
					margin-top: 0;
				}

				.card {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					line-height: 1.5;
					max-width: 140px;
					min-width: 140px;
					cursor: pointer;
					padding: 20px 20px;
					border-radius: 6px;
					font-size: 14px;
					box-shadow: 0px 0px 8.5px rgba(0, 0, 0, 0.1);

					@media screen and (max-width: $breakpoint-1) {
						max-width: 100px;
						min-width: 100px;
						font-size: 12px;
						padding: 10px;
					}

					img {
						@media screen and (max-width: $breakpoint-1) {
							max-width: 30px;
						}
					}

					&:hover,
					&.active {
						color: $pink-10;

						img {
							transition: 0.2s;
							filter: invert(36%) sepia(100%) saturate(1564%) hue-rotate(321deg)
								brightness(102%) contrast(101%);
						}
					}
				}
			}

			.form-wrapper {
				margin-top: 30px;
				textarea {
					background: $white-bg;
					border: 2px solid #4c4fff;
					color: $font-color;
					font-size: 16px;
					padding: 15px 20px;
					resize: none;
					width: 100%;
					border-radius: 12px;
				}
			}

			.sad-text {
				margin-top: 40px;
				font-size: 16px;
				position: relative;
				padding-top: 40px;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					width: 100%;
					height: 2px;
					background: linear-gradient(
						90deg,
						rgba(28, 49, 68, 0) 0%,
						rgba(28, 49, 68, 0) 0.01%,
						rgba(28, 49, 68, 0.8) 21.16%,
						#1c3144 51.07%,
						rgba(28, 49, 68, 0.8) 80.98%,
						rgba(28, 49, 68, 0) 102.55%,
						rgba(28, 49, 68, 0) 102.55%
					);

					mix-blend-mode: normal;
					opacity: 0.5;
					transform: rotate(0deg);
				}
			}

			.icons-container {
				display: flex;
				gap: 10px;
				margin-bottom: 20px;
			}

			button.download-icons-btn {
				font-family: Poppins, sans-serif;
				cursor: pointer;
				border: none;
				background: transparent;
				color: #4c4fff;
				font-size: 18px;
				text-decoration: underline;
			}
		}

		.submit-btn {
			margin-top: 35px;
			padding: 1rem 3rem;
			background-color: $pink-10;
			font-family: Poppins, sans-serif;
			font-weight: 500;
			font-size: 16px;
			cursor: pointer;
			color: white;
			border: none;
			border-radius: 8px;

			transition: 0.2s;

			&:hover {
				transform: translateY(4px);
			}
		}
	}

	* {
		box-sizing: border-box;
	}

	.body-1 {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 1.5em;
	}

	a {
		text-decoration: none;
		color: #4c4fff;
		transition: all 0.2s ease;
		font-weight: 500;

		&.primary {
			&:hover {
				text-decoration: underline;
			}
		}

		// &.secondary {
		//   color: $pink-10;

		//   &:hover {
		//     color: #FF7CA0;
		//   }
		// }

		// &.tertiary {
		//   color: $gray-10;

		//   &:hover {
		//     color: $white-10;
		//   }
		// }
	}

	aside {
		// background: linear-gradient(255.96deg, #7e94ed 0%, #344aa9 100%);
		background: url(/assets/login-bg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center 0%;

		width: 40%;
		position: relative;
		font-family: Poppins, sans-serif;
		overflow: hidden;

		@media screen and (max-width: $breakpoint-3) {
			display: none;
		}

		.content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 20px;
			height: 100%;
		}

		.overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.75);
		}

		img.login-bg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1.2);
			pointer-events: none;
		}

		img.logo {
			position: absolute;
			z-index: 10;
			max-width: 202px;
			left: 50%;
			transform: translateX(-50%);

			bottom: unset;
			top: 30px;

			@media (min-height: 660px) {
				bottom: 30px;
				top: unset;
			}
		}

		.reviews-wrapper {
			color: white;
			z-index: 100;
			margin: 150px auto 150px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 40px;
			max-width: 95%;

			.review-item {
				display: flex;
				flex-direction: column;
				font-weight: 300;
				align-items: center;
				max-width: 450px;

				p {
					margin: 0;
				}

				p.title {
					font-size: 16px;
					margin: 10px 0 5px;
				}

				p.name {
					font-size: 12px;
				}
			}
		}
	}

	section {
		flex: 1;
		position: relative;
		padding: 50px;
		max-height: 100vh;
		overflow: auto;
		text-align: center;
		display: flex;
		flex-direction: column;
		
		@media (min-height: 660px) {
			justify-content: center;
		}

		@media screen and (max-width: $breakpoint-2) {
			padding: 100px 50px;
		}

		@media screen and (max-width: $breakpoint-3) {
			text-align: center;
			padding: 50px 20px;
			max-height: none;
			overflow: visible;
		}

		h3 {
			color: $font-color;
			font-weight: 300;
			font-size: 40px;
			line-height: 130%;
			text-align: center;
			font-weight: 600;
			font-family: Poppins, sans-serif;
			max-width: 460px;
			margin: 0 auto 10px;

			em {
				color: #4c4fff;
			}

			+ p {
				margin-bottom: 20px;

				@media screen and (max-width: $breakpoint-4) {
					margin-bottom: 10px;
				}
			}
		}

		p {
			font-weight: 300;
			font-size: 16px;
			text-align: center;
			max-width: 500px;
			margin: 0 auto 0;
		}

		> .loader {
			position: relative;
			left: 0;
			top: 0;
			transform: none;
			margin: 60px auto;
		}
	}

	form {
		padding: 0;
		max-width: 400px;
		width: 100%;
		margin: 0 auto;
		max-width: 350px;
	}

	.form-row {
		margin: 0 0 20px;
		position: relative;

		input[type='checkbox'] {
			flex: 0;
		}

		input[type='email'],
		input[type='password'],
		textarea,
		input[type='text'] {
			background: white;
			border: 2px solid #f5f5f5;
			color: $font-color;
			font-size: 16px;
			padding: 8px 17px;
			max-width: 100%;
			border-radius: 5px;
			font-family: Poppins, sans-serif;

			@media screen and (max-width: $breakpoint-4) {
				font-size: 14px;
			}

			&:focus {
				border-color: #d9d9d9;
				background: white;
				color: $font-color;
			}

			::placeholder {
				color: #f5f5f5;
				font-weight: 400;
			}
		}
	}

	.terms {
		width: 100%;
		gap: 7px;

		> label {
			cursor: pointer;
			flex: 1;
			text-transform: none;
			font-size: 14px;
			font-weight: 400;

			a {
				margin-left: 5px;
				color: #adadafdf;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.social-login {
		width: 100%;
		max-width: 350px;
		margin: 0 auto;
		text-align: center;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media screen and (max-width: $breakpoint-4) {
			margin-bottom: 30px;
		}

		+ form {
			padding-top: 0;
		}
	}

	.row {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;

		> * {
			flex: 1;
		}
	}

	.auth-provider-btn {
		$google-blue: white;
		$button-active-blue: white;
		background-color: $google-blue;
		border: 2px solid #f5f5f5;
		border-radius: 5px;
		display: inline-flex;
		align-items: stretch;
		padding: 0;
		padding: 5px 20px;

		.auth-provider-icon-wrapper {
			position: relative;
			width: 40px;
			border-radius: 2px 0 0 2px;
			background-color: $white-10;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.auth-provider-icon {
			width: 22px;
			height: 22px;
		}

		.auth-provider-btn-text {
			padding: 10px 20px 10px 5px;
			color: black;
			letter-spacing: 0.2px;
			font-weight: 600;
			font-size: 16px;
		}

		&:hover {
			border-color: #d9d9d9;
		}
	}

	.or {
		font-family: Poppins, sans-serif;
		display: block;
		margin: 30px auto;
		text-transform: uppercase;
		position: relative;
		padding: 0 50px;

		font-weight: 300;
		font-size: 12px;
		line-height: 18px;
		/* identical to box height */

		text-align: center;

		color: #5f5f5f;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: calc(100% + 30px);
			transform: translate(-50%, -50%);
			width: 100%;
			height: 2px;
			background: #f5f5f5;
			z-index: 0;
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 2px;
			background: #f5f5f5;
			z-index: 0;
		}

		@media screen and (max-width: $breakpoint-4) {
			margin-bottom: 30px;
		}
	}

	.error {
		padding: 10px 15px;
		margin: 0;
		text-align: center;
		border-radius: 5px;
		border: 1px solid $pink-10;
		line-height: 1.5em;
	}

	.post-message {
		margin: 40px auto;
		text-align: center;
		line-height: 1.5em;
	}

	.buttons-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&.center {
			justify-content: center;
		}

		flex-direction: column-reverse;

		.loader {
			position: relative;
			left: 0;
			top: 0;
			transform: scale(0.7);
			transform-origin: 50% 50%;
		}

		> a + .button {
			width: auto;
		}

		.button {
			padding: 12px 20px;
			font-family: 'Poppins', sans-serif;
			font-weight: 500;
			font-size: 16px;
			min-width: 100%;
			border-radius: 5px;
			background-color: #4c4fff;
			margin-bottom: 20px;
			transition: 0.2s;
			max-height: 50px;

			&::before {
				display: none;
			}

			&:disabled {
				opacity: 0.5;
				color: #fff;
			}

			&:hover {
				background-color: #4c4fff;
				opacity: 0.8;
			}
		}
	}

	hr {
		border-color: transparent;
		margin: 0;
		width: 100%;
	}

	.with-underline {
		text-decoration: underline;
	}

	.ReactPasswordStrength {
		width: 100%;
		border: none;
		position: relative;
		z-index: 1;

		.ReactPasswordStrength-strength-desc {
			padding: 5px 3px 0 0;
			width: auto;
			font-size: 13px;
			position: absolute;
			top: 100%;
			right: 0;
		}

		.ReactPasswordStrength-strength-bar {
			position: absolute;
			top: 100%;
			left: 3px;
			margin-top: 11px;
			border-radius: 5px;
			max-width: 85%;
		}
	}

	.developer-signup-form {
		form {
			width: 100%;
			max-width: 100%;
		}

		.buttons-wrapper {
			max-width: 400px;
			margin: 0 auto;

			.button {
				background: #bba0f5;
				color: #f5f5f5;
				border-radius: 2px;

				&:hover {
					background: #976afa;
				}

				&:disabled {
					background-color: #dadadd;
					border-color: #dadadd;
				}
			}
		}

		.read-terms {
			align-items: flex-start;
			width: 100%;
			text-align: left;

			input {
				margin-right: 10px;
				margin-top: 7px;
			}

			span {
				cursor: pointer;
				flex: 1;
			}
		}
	}

	.terms-of-service {
		overflow-y: auto;
		height: 40vh;
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
		border-radius: 3px;
		margin-bottom: 40px;
		text-align: left;
		padding: 20px;
		font-family: Poppins;
		background: rgba(255, 255, 255, 0.05);

		h2 {
			font-size: 18px;
			text-align: center;
			margin: 0 0 20px;
		}

		p,
		ol {
			margin-bottom: 20px;
		}

		li {
			margin-bottom: 10px;
		}

		u {
			text-decoration: underline;
		}

		ol {
			counter-reset: item;

			li {
				&::before {
					content: counters(item, '.') '. ';
					counter-increment: item;
				}
			}
		}

		> ol {
			margin-left: 0;

			> li {
				font-weight: 700;

				> ol {
					margin-top: 10px;
					margin-left: 20px;

					li {
						font-weight: normal;
					}
				}
			}
		}
	}
}
