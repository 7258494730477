.permissions-manager {
  margin-top: 20px;

  .loader {
    position: relative;
    text-align: center;
    margin-top: 100px;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 40px 0;
  }

  th,
  td {
    border-bottom: 1px solid;
    padding: 10px 5px;
    text-align: left;
  }

  th {
    font-weight: 700;
    font-size: 13px;
    border-color: #cfd7e6;
    vertical-align: middle;
  }

  td {
    border-color: #eef1f6;
  }

  .status {
    color: #747474;
    font-style: italic;
    font-size: 85%;
  }

  .role {
    text-transform: capitalize;
  }

  .actions {
    width: 160px;
    text-align: center;

    .btn.action {
      border: none;
    }
  }

  .btn {
    padding: 7px 10px;
    font-size: 13px;
  }
}

.invite-popup {
  .popup-content {
    width: 520px;
    padding: 0;

    > h2 {
      margin: 0 0 20px;
      padding: 20px;
      background: #eaecf3;
    }

    .content {
      padding: 10px 40px 30px;
    }

    .loader {
      position: relative;
      text-align: center;
      margin-top: 40px;
    }

    input {
      width: 100%;
    }

    .buttons-wrapper {
      margin-top: 40px;
      padding: 0;
    }

    .btn {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
}
