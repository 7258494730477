@import "~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars";

.pricing-page.new-pricing .mini-bundles-wrapper {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  color: #1e2a3b;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  --lead: #6278d4;

  .button {
    font-family: inherit;
  }

  .mini-bundles-box.mini-bundles-plans {
    display: inline-flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0;
    margin: 0 auto;
    background-color: #788cde;
    border-color: #788cde;
    color: #fff;

    @media screen and (max-width: $breakpoint-3) {
      flex-direction: column;
    }
  }

  .mini-bundles-box {
    background-color: #fff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 20px;
  }

  .mini-bundles-plan {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 40px;
    border-radius: 10px;
    position: relative;

    .arrow-wrapper {
      position: absolute;
      left: 0%;
      bottom: 100%;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      display: block;
      line-height: 1;
      width: -moz-fit-content;
      width: fit-content;
      white-space: nowrap;
      transform: translate(-40%, -120%);

      @media screen and (max-width: $breakpoint-3) {
        display: none;
      }

      svg {
        position: absolute;
        right: 0;
        top: 0;
        transform: scale(0.8) translate(60%, -20%);
      }
    }

    svg {
      line-height: 0;
      margin-top: -2px;
      // color: var(--lead);
    }

    &:hover {
      background-color: #677cd0;
    }

    &.selected {
      background-color: #fff;
      color: var(--lead);
    }
  }

  .new-select-cycle-wrapper {
    margin: 30px 0 10px !important;

    .select-cycle {
      position: relative;
    }

    .select-cycle .cycle-selector {
      gap: 15px;

      h4 {
        font-size: 16px !important;

        &.active {
        }
      }

      .slider {
      }
    }

    .slider-save {
      transform: translate(10px, -8px);
      left: 100%;
      top: 50%;
      text-decoration: none;
      color: var(--lead);
      font-size: 12px;
      font-weight: 400;
      background-color: #f0f0f0;
      border-radius: 50em;
      padding: 3px 8px;
      display: block;
      height: 20px;
      text-align: center;
      width: auto;
      padding-top: 4px;

      @media screen and (max-width: $breakpoint-3) {
        display: none;
      }
    }
  }

  .mini-bundles-credits-left {
    background: #ffebef;
    border: 1px solid #d18d9c;
    border-radius: 10px;
    color: #1e2a3b;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;
    width: 960px;
    max-width: 100%;
    margin: 0 auto 20px;

    .mini-bundles-credits-left-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .button {
      position: relative;
      transition: all 0.2s ease;
      box-sizing: border-box;
      font-size: 14px;
      font-family: var(--font);
      border-radius: 5px;
      padding: 8px 20px;
      font-weight: 500;
      opacity: 1;
      box-shadow: none !important;
      color: #fff;
      border: 1px solid var(--lead-blue);
      background-color: var(--lead-blue);

      &:hover {
        transform: none;
        background-color: #3C4F9D;
        color: #fff;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  .plan-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    padding: 50px 20px;

    svg {
      font-size: 72px;
      color: #30bb57;
    }

    h4 {
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      color: #1e2a3b;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      color: #1e2a3b;
      margin: 0;
      line-height: 1.5;
    }

    .button {
      // width: 100%;
      gap: 10px;
      font-size: 16px;
      background-color: transparent;
      color: var(--lead);
      border: 1px solid var(--lead);

      &:hover {
        background-color: var(--lead);
        color: #fff;
      }
    }
  }

  .mini-bundles-main-section {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    width: 840px;
    max-width: 100%;
    margin: 0 auto;

    > * {
      flex: 1;
    }

    @media screen and (max-width: $breakpoint-3) {
      flex-direction: column;

      > * {
        flex: 0;
      }
    }
  }

  .mini-bundles-pricing {
    position: relative;
    // overflow: hidden;

    // .after-discount {
    //   position: absolute;
    //   right: -40px;
    //   top: 20px;
    //   background-color: #e96881;
    //   color: #fff;
    //   padding: 5px 50px;
    //   transform: rotate(45deg);
    //   text-align: center;
    //   font-size: 12px;
    // }

    h3 {
      color: inherit;
      font-weight: 600;
      font-size: 30px;
      line-height: 1.5;
      margin: 0 0 5px;

      span {
        font-size: 50%;
        font-weight: 300;
        color: #a7a6b1;
      }
    }

    .billing-period {
      font-size: 12px;
      color: inherit;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        color: var(--lead);
        font-size: 20px;
      }
    }
  }

  .sword {
    position: absolute;
    right: -60px;
    top: -90px;
    fill: #fff;
    z-index: -1;
    transform: scale(0.7);
  }

  .throwing-stars {
    position: absolute;
    left: -70px;
    bottom: -90px;
    fill: var(--lead);
    z-index: -1;
    transform: scale(0.5);
  }

  .mini-bundles-selected-apps-wrapper {
    h4 {
      font-weight: 600;
      font-size: 18px;
      margin: 0 0 20px;
      display: flex;
      align-items: center;

      svg {
        color: #b4b4b4;
        cursor: pointer;
        transition: 0.2s;
        margin-left: 8px;
        font-size: 16px;

        &:hover {
          color: var(--text);
        }
      }
    }

    .button {
      width: 100%;
      // color: #b4b4b4;
      // border-color: #b4b4b4;
      // border-style: dashed;
      // border-width: 2px;
      gap: 10px;
      font-size: 16px;
      background-color: transparent;
      color: var(--lead);
      border: 1px solid var(--lead);

      &:hover {
        background-color: var(--lead);
        color: #fff;
      }

      // &:hover {
      //   color: #969595;
      //   border-color: #969595;
      // }
    }
  }

  .mini-bundles-selected-apps {
    min-height: 90px;
    max-height: 150px;
    overflow: auto;
  }

  .mini-bundles-selected-app {
    background: #fff;
    border-bottom: 1px solid #eeeeee;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 15px;
    gap: 10px;
    position: relative;

    .tooltip {
      line-height: 0;

      .tooltip-pointer {
        color: #fff;
        background-color: var(--lead);
        width: 17px;
        height: 17px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        font-weight: 400;
      }

      .tooltip-content {
        z-index: 99;
        color: var(--lead-text);
        font-size: 12px;
        font-weight: 400;
        background-color: #f0f0f0;
        width: 290px;
        border-radius: 10px;

        &::before {
          border-top-color: #f0f0f0;
        }
      }
    }

    &:hover {
      h5 {
        .trash {
          opacity: 1;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &.credits {
      background-color: #f7f8ff;
      border-radius: 5px;
      border-bottom: none;
      padding: 20px 15px;

      h5 {
        font-weight: 600;

        .select-apps {
          cursor: pointer;
          color: var(--lead);
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;

          &:hover {
            color: #465cb4;
          }
        }
      }
    }

    h5 {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 14px;

      .credits-left {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .trash {
        opacity: 0;
        margin-left: 5px;
      }
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50em;
    }

    .mini-bundles-selected-app-mask {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      gap: 5px;
      align-items: flex-end;
      justify-content: flex-end;

      .orig-price {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        text-decoration-line: line-through;
        color: #8e949d;
      }

      // svg {
      //   &.check {
      //     color: #30bb57;
      //   }
      // }
    }

    svg.trash {
      color: #cacaca;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        color: #e96881;
      }
    }

    &:hover {
      .mini-bundles-selected-app-mask .trash {
        opacity: 1;
      }
    }
  }

  .mini-bundles-checkout-summary {
    // margin-top: 20px;
    padding: 15px 20px;
    border-top: 1px solid #ccc;
  }

  .mini-bundles-checkout-summary-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 5px;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
      margin-top: 10px;
    }

    .discount {
      color: #30bb57;
    }

    > :last-child {
      text-align: right;
    }

    .period {
      font-weight: 300;
      font-size: 70%;
      color: #8e949d;
    }

    .small {
      display: block;
      text-align: right;
      font-weight: 300;
      font-size: 70%;
    }
  }

  .mini-bundles-checkout {
    margin: 0 auto;
    text-align: center;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      margin: 0 0 10px;

      span {
        color: var(--lead);

        &.error-message {
          color: #e96881;
        }
      }
    }

    .button {
      background-color: var(--lead);
      color: #fff;
      border: none;
      gap: 10px;
      font-size: 16px;

      &:hover {
        background-color: #465cb4;
        color: #fff;
      }
    }
  }
}
