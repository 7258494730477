.webhook-message-viewer {
  flex: 1;
  overflow: auto;
  padding-left: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.06);

  .webhook-message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 6px 0 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.55);

    .webhook-message-resend {
      font-family: inherit;
      padding: 4px 10px;
      background: transparent;
      border: none;
      color: rgba(0, 0, 0, 0.6);
      &:hover {
        color: rgba(0, 0, 0, 0.8);
      }
      cursor: pointer;
      &:disabled {
        cursor: auto;
      }
    }
    .webhook-message-type {
      font-size: 1.5em;
    }
  }
  .webhook-message-content {
    h4 {
      color: rgba(0, 0, 0, 0.45);
      font-weight: 600;
    }
    .webhook-message-request {
      background-color: #fff;
    }
    .webhook-message-response {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);

      > span {
        padding-top: 2px;
      }

      .webhook-messages-response-code {
        padding: 2px 4px;
        font-weight: 400;
        border-radius: 5px;
      }
    }
  }
}
