.popup.upgrade-popup {
	$popup-width: 90%;
	cursor: default;

	&.closed {
		display: none;
	}

	.popup-content {
		width: $popup-width;
		height: $popup-width;
		padding: 0;
		background: none;

		.close-popup {
			color: #fff;
		}
	}

	section {
		padding: 0;
		height: 100%;
		
		&#commonninja-dashboard-iframe-wrapper {
			> p.center {
				padding: 10px 20px;
				margin: 0;
			}
		}
		
		iframe {
			margin-top: -41px;
			width: 100%;
			min-height: 100%;
			background-color: #fff;
		}
	}
}
