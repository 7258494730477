.developer-page .app-content-wrapper.collection-documents {
	width: 100%;
	max-width: 1400px;
	
	.add-field-box {
		width: 100%;
		padding: 30px;
		height: fit-content;
		background: #ffffff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
		border-radius: 10px;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 25px;
		justify-content: center;

		p {
			font-size: 18px;
			margin: 0;
			text-align: center;
			max-width: 300px;
		}
	}
}
