.checkout-success {
  width: 100%;
  height: 100%;
  min-height: 680px;
  overflow: visible;
  overflow-x: hidden;
  color: #000;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.4;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background: linear-gradient(
      0deg,
      #3C4F9D 0%,
      #4C4FFF 100%
    );
    z-index: 0;
  }

  > * {
    z-index: 1;
    position: relative;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    background-color: #fff;
    width: 540px;
    max-width: 100%;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid #f0f0f0;
    position: relative;
    margin: 0 20px;

    > * {
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: 0;
      border-radius: inherit;
    }
  }

  img {
    max-width: 100%;
    max-height: 300px;
  }

  img.cards {
    width: 180px;
    margin: 10px 0;
  }

  .cn-stars,
  .cn-sword {
    position: absolute;
    z-index: -1;
  }

  .cn-stars {
    left: 0;
    bottom: 0;
    width: 150px;
    transform: translate(-65%, 45%);
  }

  .cn-sword {
    right: 0;
    top: 0;
    width: 150px;
    transform: translate(30%, -50%);
  }

  h2 {
    font-size: 1.5rem;
    color: #4C4FFF;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 24px;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
  }

  a {
    color: #4C4FFF;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    fill: #4C4FFF;
    color: inherit;
  }
}
