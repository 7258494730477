.styled-media-manager-files-figure {
  width: 150px;
  border-radius: 5px;
  background-color: #fafafc;
  font-size: 0.9rem;
  // cursor: pointer;
  transition: 0.2s;
  position: relative;

  &.selected {
    box-shadow: 0 0 0 2px #6881e9;
  }

  &:hover {
    figcaption {
      color: #111;
    }

    .popover-trigger {
      opacity: 1;
    }

    img {
      opacity: 0.15;
    }
  }

  .figure-media {
    width: 100%;
    height: 100px;
    background-color: #fafafc;
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0 0;
    overflow: hidden;

    svg {
      font-size: 2rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s;
  }

  figcaption {
    padding: 3px 5px;
    width: 100%;
    color: #333;
    transition: 0.2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    height: 25px;
    font-size: 12px;

    .input-container {
      margin: 0;
    }

    input {
      width: 100%;
      padding: 1px 3px;
      font-size: 12px;
      border-radius: 2px;
    }
  }

  .popover-trigger {
    position: absolute;
    right: 3px;
    top: 0;
    font-size: 24px;
    color: #6881e9;
    transition: 0.2s;
    cursor: pointer;
    opacity: 0;

    &:hover {
      color: #3e53a9;
    }
  }
}

.styled-media-manager-files-figure-actions {
  background-color: #fafafc;
  color: #333;
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 7px 0;
  width: 160px;
  border-radius: 5px;
  box-shadow: 0 1px 10px rgba(255, 255, 255, 0.05);
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 25px;

  .input-container {
    margin: 0;
  }

  .button {
    color: #000;
    padding: 5px 15px;
    text-decoration: none;
    position: relative;
    font-size: inherit;
    transition: 0.2s;
    width: 100%;
    justify-content: flex-start;
    border-radius: 0;
    gap: 10px;
    border: none !important;

    &.delete {
      color: #711414;

      svg {
        font-size: 1.15rem;
        margin-top: -2.5px;
      }

      &:hover {
        color: #711414;
      }
    }

    svg {
      font-size: 1.05rem;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.075);
      color: #000;
    }
  }
}