@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.pricing-page .pricing-loader {
	// padding-top: 0;

	.mini-box {
		box-shadow: none !important;
		background-color: #fff !important;

		.title {
			margin: 0 auto 20px;
		}
	}
}

.website-mode.pricing-page.new-pricing {
	padding-top: 0;
}

.pricing-page.new-pricing {
	--lead-blue: #4c4fff;
	--lead-text: #010101;
	--bg-color: #fff;
	--box-bg: #f0f0f0;
	--font: 'Poppins';
	--header-text: #fff;

	padding: 0;
	color: var(--lead-text);
	font-family: var(--font);
	width: 100%;

	&.vendor-vode {
		--bg-color: #fff;
	}

	.new-pricing-wrapper {
		position: relative;
		padding: 20px 0 40px;

		.svg-star {
			position: absolute;
			z-index: 0;
			left: 50%;
			top: 0;

			@media screen and (max-width: $breakpoint-4) {
				display: none;
			}

			&.star1 {
				transform: translate(320px, 120px);
			}

			&.star2 {
				transform: translate(240px, 40px);
			}

			&.star3 {
				transform: translate(-300px, 100px) scale(0.75);
			}
		}

		&.widgets-based {
			--header-text: #1e2a3b;
			background-color: #fff;
		}

		&:not(.widgets-based)::before {
			content: '';
			background: linear-gradient(
				255.96deg,
				rgb(125, 147, 237) 0%,
				rgb(48, 71, 166) 100%
			);
			z-index: -1;
			width: 100%;
			height: 600px;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.widgets-based {
		.new-pricing-table-wrapper .pricing-table {
			align-items: stretch;
		}

		.new-select-cycle-wrapper {
			.slider-save {
				color: var(--lead-text);
				font-size: 14px;
				font-weight: 300;
				transform: translate(-56%, -90%);

				svg {
					display: block;
				}
			}

			.cycle-selector {
				gap: 0;
				border-radius: 50px;
				border: 2px solid #f5f5f5;
				background: #fff;
				box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.1);
				padding: 5px;
				display: flex;
				align-items: center;

				button {
					cursor: pointer;
					border: none;
					outline: none;
					background-color: transparent;
					padding: 10px 20px;
					border-radius: 50px;
					font-family: Poppins;
					font-size: 18px;
					font-style: normal;
					font-weight: 300;
					transition: 0.2s;

					&.active {
						color: var(--lead-blue);
						background-color: #f5f5ff;
					}

					&:hover {
						color: var(--lead-blue);
					}
				}
			}

			input:checked + .slider {
				background: #f5f5f5; // #D1D9FB
			}

			.slider {
				background: var(--lead-blue);
			}

			// .slider:before {
			//   background: #fff;
			// }
		}
	}

	.number-of-widgets-selector-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 50px;
		gap: 5px;
	}

	.number-of-widgets-selector-status {
		color: var(--lead-blue);
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
	}

	.number-of-widgets-selector {
		border-radius: 12px;
		border: 2px solid #f5f5f5;
		background: #f8faff;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
		padding: 12px 20px;
		font-size: 16px;

		@media screen and (max-width: $breakpoint-4) {
			flex-direction: column;
			text-align: center;
		}

		.number-of-widgets-select {
			background: #fff;
			position: relative;

			&::after {
				content: '';
				width: 0.8em;
				height: 0.5em;
				background-color: var(--lead-text);
				display: block;
				clip-path: polygon(100% 0%, 0 0%, 50% 100%);
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		select {
			appearance: none;
			background-color: transparent;
			border: none;
			margin: 0;
			font-family: inherit;
			font-size: inherit;
			cursor: inherit;
			line-height: inherit;
			border-radius: 8px;
			border: 2px solid #d2ddff;
			background: transparent;
			color: var(--lead-text);
			font-size: 16px;
			cursor: pointer;
			text-align: left;
			padding: 10px 15px;
			max-width: 90px;
		}
	}

	.number-of-widgets-checkbox-selector {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
		justify-content: center;
		align-items: center;

		.number-of-widgets-checkbox-title {
			color: var(--lead-text);
			text-align: center;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			display: block;
		}

		.number-of-widgets-checkbox-select {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			gap: 0;
			border-radius: 8px;
			border: 2px solid #E6E6E6;
			background: #fff;
			box-shadow: 0px 0px 20px 0px rgba(76, 79, 255, 0.15);
			overflow: hidden;
		}

		.checkbox-option {
			color: #5f5f5f;
			text-align: center;
			font-family: Poppins;
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			padding: 10px 20px;
			cursor: pointer;
			transition: all 0.2s;
			border-right: 2px solid #E6E6E6;

			&:hover {
				background: #F8F8FF;
			}

			&:last-child {
				border-right: none;
			}

			&.active {
				background: #4C4FFF;
				color: #fff;
			}

			input {
				display: none;
			}
		}
	}

	&.dark .new-pricing-wrapper {
		&::before {
			background: #171b2c !important;
		}
	}

	.active-subscription,
	p.center,
	.message,
	.pricing-loader {
		color: var(--header-text);
	}

	// Service name is bundle
	// .bundles {
	//   .tabs {
	//     display: none;
	//   }
	// }

	.tabs {
		background: rgba(255, 255, 255, 0.35);
		width: fit-content;
		padding: 0;
		display: block;
		margin: auto;
		border-radius: 50em;
		line-height: 0;

		.tab-trigger {
			border-radius: 50em;
			color: #fff;
			border: none;
			font-family: var(--font);
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 1.5;
			padding: 7px 35px;
			margin: 0px 0 -1px;

			&.active {
				background: #fff;
				color: var(--lead-blue);
			}
		}
	}

	.app-name-title {
		font-weight: 300;
		line-height: 1.5;
		color: var(--header-text);
		margin: 0px auto 10px;
		font-size: 75px;
	}

	.app-guarantee {
		font-weight: 300;
		line-height: 1.5;
		color: var(--header-text);
		margin: 0px auto 20px;
		font-size: 18px;
	}

	.checkout-redirect {
		padding: 40px;
		min-height: 400px;

		.loader {
			position: relative;
		}

		p {
			color: inherit;
		}
	}

	// Cycle selector
	.new-select-cycle-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-bottom: 60px;
		margin-top: 40px;
		position: relative;

		.slider-save {
			// color: var(--lead-text);
			position: absolute;
			left: 50%;
			top: calc(100% + 25px);
			font-size: 14px;
			font-weight: 300;
			color: var(--header-text);
			display: block;
			line-height: 1;
			width: -moz-fit-content;
			width: fit-content;
			white-space: nowrap;
			font-size: 14px;
			font-weight: 300;
			transform: translate(-56%, -90%);

			svg {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translate(50px, -80%);
				display: block;
			}
		}

		.cycle-selector {
			gap: 0;
			border-radius: 50px;
			border: 2px solid #f5f5f5;
			background: #fff;
			box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.1);
			padding: 5px;
			display: flex;
			align-items: center;

			button {
				cursor: pointer;
				border: none;
				outline: none;
				background-color: transparent;
				padding: 10px 20px;
				border-radius: 50px;
				font-family: Poppins;
				font-size: 18px;
				font-style: normal;
				font-weight: 300;
				transition: 0.2s;

				&.active {
					color: var(--lead-blue);
					background-color: #f5f5ff;
				}

				&:hover {
					color: var(--lead-blue);
				}
			}
		}

		input:checked + .slider {
			background: #f5f5f5; // #D1D9FB
		}

		.slider {
			background: var(--lead-blue);
		}
	}

	// Price wrapper
	.plan-price-wrapper {
		margin: 5px 0;
		padding: 0;
		position: relative;
		font-weight: 600;
		font-size: 30px;
		line-height: 1.5;
		text-align: left;
		color: var(--lead-text);
		font-family: var(--font);

		span {
			font-weight: 300;
			font-size: 20px;
			color: #a7a6b1;
			margin-left: 3px;
		}

		+ .per-month-txt {
			line-height: 1.5;
			font-weight: normal;
			margin: 5px 0;
			font-weight: 400;
			font-size: 12px;
			color: var(--lead-text);
		}
	}

	.after-discount {
		text-decoration: none;
		color: var(--lead-text);
		font-size: 12px;
		font-weight: 400;
		background-color: #f0f0f0;
		border-radius: 50em;
		padding: 3px 8px;
		margin-left: 5px;
	}

	// Checkout buttons
	.checkout-buttons-wrapper .button {
		margin: 20px 0;
		width: 100%;
		color: var(--lead-blue);
		background: #f5f5ff;
		position: relative;
		transition: all 0.2s ease;
		box-sizing: border-box;
		font-size: 16px;
		font-family: var(--font);
		border: 1px solid #f5f5ff;
		border-radius: 5px;
		padding: 8px 20px;
		font-weight: 500;
		opacity: 1;
		box-shadow: none !important;

		&:hover {
			transform: none;
			background-color: var(--lead-blue);
			border-color: var(--lead-blue);
			color: #fff;
		}

		&:disabled {
			background: #f0f0f0;
			color: #a7a6b1;
			border: 1px solid #f0f0f0;
			cursor: default;
		}

		&.amit-button  {
			background-color: #f5f5ff;
			color: #202041;
			border: none;
			&:hover,
			&:active {
			  background-color: #e4e4ff;
			  color: #202041;
			}
		  }
	}

	.pro,
	.premium,
	.expert,
	.plus {
		// .plan-price-wrapper {
		//   color: var(--lead-pink);

		//   span:last-child {
		//     color: var(--lead-pink);
		//   }
		// }

		.checkout-buttons-wrapper .button:not(:disabled) {
			color: #fff;
			border: 1px solid var(--lead-blue);
			background-color: var(--lead-blue);

			&:hover {
				background-color: #3c4f9d;
				color: #fff;
			}
		}
	}

	// Pricing table
	.new-pricing-table-wrapper {
		margin: 0 auto;
		align-items: center;
		font-family: var(--font);

		.pricing-table {
			gap: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			flex-direction: row;
			margin: 0;

			.tooltip {
				margin-left: 5px;

				.tooltip-pointer {
					background: transparent;
					color: var(--lead-blue);
					border: 1px solid var(--lead-blue);
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.tooltip-content {
					z-index: 99;
					background: white;
					box-shadow: #d9d9d9 0 0 20px;
					color: var(--lead-text);
					font-size: 14px;
					border-radius: 10px;

					&.top::before {
						border-top-color: white;
					}
				}
			}

			.sword {
				position: absolute;
				right: -60px;
				top: -90px;
				fill: #fff;
				z-index: -1;
				transform: scale(0.7);
			}

			.throwing-stars {
				position: absolute;
				left: -70px;
				bottom: -90px;
				fill: #6278d4;
				z-index: -1;
				transform: scale(0.5);
			}

			.mini-box {
				position: relative;
				text-align: left;
				box-sizing: border-box;
				width: 290px;
				flex-basis: 290px;
				max-width: 290px;
				min-height: fit-content;
				color: var(--lead-text);
				font-weight: 300;
				font-size: 14px;
				line-height: 1.3;
				background-color: #fff;
				border: 1px solid #eeeeee;
				border-radius: 10px;
        
        .inner-content {
          padding: 20px;
        }

				&:first-child {
					.decoration {
						left: 0;
						bottom: 0;
						transform: scale(0.6) translate(-90%, 90%);
					}
				}

				&:last-child {
					.decoration {
						right: 0;
						transform: scale(0.6) translate(50%, -100%);
					}
				}

				.title {
					font-weight: 300;
					font-size: 18px;
					color: var(--lead-text);
					line-height: 1.5;
					margin: 0;
					text-transform: capitalize;
				}

				&.pro,
				&.premium,
				&.expert {
					// TODO change border color
					// border: 3px solid var(--lead-blue);
					
					padding: 3px;
					background-origin: border-box;
					background-clip: content-box, border-box;
					background-image: linear-gradient(
							180deg,
							#fff 6.49%,
							#fff 75.1%,
							#fff 100%
						),
						linear-gradient(180deg, #66f, #ff979c);

					&::after {
						content: 'Popular';
						position: absolute;
						right: 10px;
						top: 0;
						background: var(
							--Gradient,
							linear-gradient(270deg, #ff979c 0%, #4c4fff 100%)
						);
						color: #fff;
						font-size: 14px;
						font-weight: 600;
						padding: 5px 15px;
						transform: translateY(-50%);
						border-radius: 50em;
					}
				}

				.see-all-button {
					margin-top: 5px;
					font-size: 12px;
				}

				main {
					text-align: left;

					> div {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						gap: 5px;
						position: relative;
						margin-bottom: 10px;
						color: var(--lead-text);

						> svg {
							font-size: 20px;
							color: var(--lead-blue);
							width: 20px;

							&.unchecked {
								color: #a7a6b1;
							}
						}

						// .feature-display {
						//   &.unchecked {
						//     text-decoration: line-through;
						//     text-decoration-thickness: 2px;
						//   }
						// }

						.value {
							font-weight: 500;

							&:empty {
								display: none;
							}

							i,
							svg {
								color: var(--lead-blue);
								margin-right: 3px;

								&.fa-times {
									color: #e0e3ee;
								}
							}
						}

						.feature-display {
							text-transform: capitalize;
						}

						.scroll-to-comparison-table {
							color: var(--lead-blue);
							cursor: pointer;
							display: flex;
							align-items: center;
							gap: 5px;

							&:hover {
								text-decoration: underline;
							}
						}

						.tooltip {
							margin-left: 0px;
							transform: scale(0.9);
							z-index: 10;
						}

						.tooltip-pointer {
							background: none;
							color: var(--lead-blue);
							border: 1px solid var(--lead-blue);
							transition: all 0.2s ease;
							font-size: 10px;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 13px;
							height: 13px;
						}

						.tooltip-content {
							z-index: 99;
							background: white;
							box-shadow: #d9d9d9 0 0 20px;
							color: var(--lead-text);
							font-size: 12px;
							border-radius: 10px;

							&.top::before {
								border-top-color: white;
							}
						}
					}
				}
			}
		}
	}

	// Full comparison table
	.comparison-table-wrapper {
		font-family: var(--font);
		display: flex;
		flex-direction: column;
		max-width: 1200px;
		margin: 100px auto 40px;

		.full-plan-btn {
			font-family: var(--font);
			cursor: pointer;
			align-self: center;
			margin: 0 0 120px;
			font-weight: 500;
			font-size: 18px;
			color: var(--lead-blue);
			line-height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			transition: all 0.2s;
			position: relative;
			background: none;
			border: none;
			box-shadow: none;

			&::before {
				content: none;
			}

			&:hover {
				transform: none;
				box-shadow: none;
			}

			&.browser-safari {
				border: 2px solid #4881fd;
			}
		}

		.comparison-table-overflow {
			max-width: 100%;

			@media screen and (max-width: 1200px) {
				overflow: auto;
			}
		}

		.comparison-table {
			width: 100%;
			min-width: 1200px;
			color: var(--lead-text);

			header {
				width: 100%;
				justify-content: center;
				background-color: transparent;
				display: grid;
				grid-auto-flow: column;
				grid-auto-columns: 1fr;
				border-bottom: 1px solid #d3d3d3;

				&:first-child {
					position: sticky;
					top: 0;
					background-color: #fff;
					z-index: 20;

					.group-title {
						border-right: 1px solid #d3d3d3;
					}
				}

				> * {
					padding: 20px;
				}

				.group-title {
					text-align: left;
					margin: 0;
					font-weight: 600;
					box-sizing: border-box;
					background-color: #fff;
					z-index: 1;
					display: flex;
					align-items: flex-end;
					justify-content: flex-start;
					padding: 20px;

					h4 {
						line-height: 1.5;
						font-size: 20px;
						font-weight: 600;
						margin: 0;
					}

					@media screen and (max-width: $breakpoint-4) {
						position: unset;
					}
				}

				.plan-container {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					gap: 10px;
					border-right: 1px solid #d3d3d3;

					&:last-child {
						border-right: none;
					}

					.title {
						margin: 0;
						font-weight: 600;
						font-size: 18px;
						color: var(--lead-text);
						text-transform: capitalize;
					}

					.checkout-buttons-wrapper .button {
						margin-bottom: 0;
					}
				}
			}
		}

		.plan-price-wrapper {
			text-align: center;

			> div {
				height: 20px;
				margin-top: -10px;
			}
		}

		.after-discount {
			position: relative;
			display: inline-block;
			bottom: 0;
			font-size: 12px;
		}

		.pricing-table-content-wrapper {
			width: 100%;

			&:last-child {
				border-radius: 0 0 12px 12px;
			}

			.pricing-table-content {
				margin-bottom: 0;
				border-bottom: 1px solid #d3d3d3;
				width: 100%;
				align-items: stretch;
				justify-content: space-between;
				padding: 0;
				font-size: 16px;
				color: var(--lead-text);
				display: grid;
				grid-auto-flow: column;
				grid-auto-columns: 1fr;

				> * {
					margin-bottom: 0;
					padding: 20px;
					border-right: 1px solid #d3d3d3;

					&:last-child {
						border-right: none;
					}
				}

				.feature-name {
					text-align: left;
					font-weight: 300;
					line-height: 1.3;
					font-size: 14px;
					color: var(--lead-text);
					box-sizing: border-box;
					background-color: transparent;
					// z-index: 10;
					display: flex;
					align-items: center;
					text-transform: capitalize;

					@media screen and (max-width: $breakpoint-4) {
						position: unset;
					}
				}

				.tooltip {
					margin-left: 5px;

					.tooltip-pointer {
						background: transparent;
						color: var(--lead-blue);
						border: 1px solid var(--lead-blue);
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.tooltip-content {
						z-index: 99;
						background: white;
						box-shadow: #d9d9d9 0 0 20px;
						color: var(--lead-text);
						font-size: 12px;
						border-radius: 10px;
						text-transform: none;

						&.top::before {
							border-top-color: white;
						}
					}
				}

				.feature-value {
					text-align: center;
					font-weight: 600;
					font-size: 18px;

					svg {
						transform: scale(0.8);

						&.checked {
							color: var(--lead-blue);
							transform: scale(1.7);
						}
					}

					@media screen and (max-width: $breakpoint-4) {
						font-size: 15px;
					}
				}
			}
		}
	}
}

.popup.cancellation-popup {
	.popup-content h2 {
		padding: 0;
	}
}

.popup.switch-plan-popup-wrapper {
	--lead-blue: #4C4FFF;
	--lead-text: #1e2a3b;
	--bg-color: #fff;
	--box-bg: #f0f0f0;
	--font: 'Poppins';
	--header-text: #fff;

	.popup-content {
		background: #fff;
		padding: 40px 40px 30px;
		width: 660px;

		@media screen and (max-width: $breakpoint-4) {
			padding: 20px;
		}
	}

	.switch-plan-popup-header {
		display: flex;
		justify-content: center;
		align-items: stretch;
		gap: 20px;
		color: var(--lead-text);
		font-family: Poppins;
		font-size: 16px;
		font-weight: 600;
		padding: 0 10px 15px;
		position: relative;

		@media screen and (max-width: $breakpoint-4) {
			display: none;
		}

		svg {
			position: absolute;
			top: -5px;
			left: 50%;
			transform: scale(1.4) translateX(-60%) translateY(10px) rotate(-22deg);
		}

		span {
			flex: 1;
			text-align: center;
		}
	}

	.switch-plan-popup-footer {
		padding-top: 20px;
		position: relative;
	}

	.button {
		width: 100%;
		color: #fff;
		background: var(--lead-blue);
		position: relative;
		transition: all 0.2s ease;
		box-sizing: border-box;
		font-size: 14px;
		font-family: var(--font);
		border: 1px solid #f5f5f5;
		border-radius: 5px;
		padding: 8px 20px;
		font-weight: 500;
		opacity: 1;
		box-shadow: none !important;

		&:hover {
			transform: none;
			background-color: #3c4f9d;
			color: #fff;
		}

		&:disabled {
			background: #f0f0f0;
			color: #a7a6b1;
			border: 1px solid #f0f0f0;
			cursor: default;
		}
	}

	// Price wrapper
	.switch-plan-popup-plans {
		display: flex;
		justify-content: center;
		align-items: stretch;
		gap: 20px;

		@media screen and (max-width: $breakpoint-4) {
			flex-direction: column;
		}
	}

	.plan-price-wrapper {
		margin: 5px 0;
		padding: 0;
		position: relative;
		font-weight: 600;
		font-size: 30px;
		line-height: 1.5;
		text-align: left;
		color: var(--lead-text);
		font-family: var(--font);

		span {
			font-weight: 300;
			font-size: 20px;
			color: #a7a6b1;
			margin-left: 3px;
		}

		+ .per-month-txt {
			line-height: 1.5;
			font-weight: normal;
			margin: 5px 0;
			font-weight: 400;
			font-size: 12px;
			color: var(--lead-text);
		}
	}

	.after-discount {
		text-decoration: none;
		color: var(--lead-text);
		font-size: 12px;
		font-weight: 400;
		background-color: #f0f0f0;
		border-radius: 50em;
		padding: 3px 8px;
		margin-left: 5px;
	}

	// Pricing table
	.new-pricing-table-wrapper {
		margin: 0;
		align-items: center;
		font-family: var(--font);

		&:last-child {
			.pricing-table .mini-box {
				border: 3px solid var(--lead-blue);
			}
		}

		.pricing-table {
			gap: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			flex-direction: row;
			margin: 0;

			.tooltip {
				margin-left: 5px;

				.tooltip-pointer {
					background: transparent;
					color: var(--lead-blue);
					border: 1px solid var(--lead-blue);
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.tooltip-content {
					z-index: 99;
					background: white;
					box-shadow: #d9d9d9 0 0 20px;
					color: var(--lead-text);
					font-size: 14px;
					border-radius: 10px;

					&.top::before {
						border-top-color: white;
					}
				}
			}

			.mini-box {
				position: relative;
				text-align: left;
				box-sizing: border-box;
				width: 290px;
				flex-basis: 290px;
				max-width: 290px;
				min-height: fit-content;
				color: var(--lead-text);
				font-weight: 300;
				font-size: 14px;
				line-height: 1.3;
				background-color: #fff;
				border: 1px solid #eeeeee;
				border-radius: 10px;
				padding: 20px;

				&:first-child {
					.decoration {
						left: 0;
						bottom: 0;
						transform: scale(0.6) translate(-90%, 90%);
					}
				}

				&:last-child {
					.decoration {
						right: 0;
						transform: scale(0.6) translate(50%, -100%);
					}
				}

				.title {
					font-weight: 400;
					font-size: 18px;
					color: var(--lead-text);
					line-height: 1.5;
					margin: 0;
					text-transform: capitalize;
				}

				main {
					text-align: left;
					margin-top: 20px;

					> div {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						gap: 1px;
						position: relative;
						margin-bottom: 10px;
						color: var(--lead-text);

						> svg {
							font-size: 20px;
							color: var(--lead-blue);
							width: 20px;

							&.unchecked {
								color: #a7a6b1;
							}
						}

						.value {
							font-weight: 500;

							&:empty {
								display: none;
							}

							i,
							svg {
								color: var(--lead-blue);
								margin-right: 3px;

								&.fa-times {
									color: #e0e3ee;
								}
							}
						}

						.feature-display {
							text-transform: capitalize;
						}

						.tooltip {
							margin-left: 5px;
							transform: scale(0.9);
							z-index: 10;
						}

						.tooltip-pointer {
							background: none;
							color: var(--lead-blue);
							border: 1px solid var(--lead-blue);
							transition: all 0.2s ease;
							font-size: 14px;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.tooltip-content {
							z-index: 99;
							background: white;
							box-shadow: #d9d9d9 0 0 20px;
							color: var(--lead-text);
							font-size: 12px;
							border-radius: 10px;

							&.top::before {
								border-top-color: white;
							}
						}
					}
				}
			}
		}
	}
}
.popup.cannot-downgrade-popup-wrapper {
	--lead-blue: #4C4FFF;
	--lead-text: #1e2a3b;
	--bg-color: #fff;
	--box-bg: #f0f0f0;
	--font: 'Poppins';
	--header-text: #fff;

	.popup-content {
		background: #fff;
		padding: 20px;
		width: 520px;

		@media screen and (max-width: $breakpoint-4) {
			padding: 10px;
		}
	}

	h2 {
		padding: 0 0 20px;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		color: var(--lead-text);
		margin: 0 0 20px;
		border-bottom: 1px solid var(--box-bg);
	}

	p {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		color: var(--lead-text);
		line-height: 1.5;
	}

	.popup-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.button {
		color: #99a1bf;
		position: relative;
		transition: all 0.2s ease;
		box-sizing: border-box;
		font-size: 14px;
		font-family: var(--font);
		border: 1px solid transparent;
		border-radius: 5px;
		padding: 8px 20px;
		font-weight: 500;
		opacity: 1;
		box-shadow: none !important;

		&:hover {
			color: var(--lead-text);
		}

		&.go-to-dashboard-button {
			color: var(--lead-blue);
			color: var(--lead-blue);
			background: #f5f5f5;
			border: 1px solid #f5f5f5;

			&:hover {
				transform: none;
				background-color: var(--lead-blue);
				border-color: var(--lead-blue);
				color: #fff;
			}
		}
	}
}
