@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.new-apps {
	position: relative;

	.cards {
		max-width: 600px;
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		gap: 30px;

		.card {
			display: flex;
			gap: 30px;
			border-radius: 10px;
			padding: 0;
			box-shadow: none;
			width: 100%;
			min-height: 275px;
			min-height: 175px;
			background-color: #fff;
			border: 1px solid #F0F0F0;

			@media (max-width: $breakpoint-3) {
				min-height: 200px;
			}

			.img-container {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 10px 0 0 10px;
				min-height: 100%;
				min-height: 100%;
				width: 200px;

				@media (max-width: $breakpoint-3) {
					min-width: unset;
				}

				img {
					object-fit: cover;
					width: 90%;
				}
			}

			.details {
				display: flex;
				flex-direction: column;
				gap: 10px;
				padding: 30px 30px 30px 0;
				color: #1e2a3b;
				width: 100%;

				h3 {
					font-weight: 600;
					font-size: 18px;
					line-height: 22px;
					margin: 0;
				}

				p {
					margin: 0;
					font-weight: 300;
					font-size: 14px;
					line-height: 20px;
					max-width: 300px;
				}

				a {
					margin-top: 10px;
					align-self: flex-end;
					margin-top: auto;
					font-weight: 500;
				}
			}
		}
	}
}
