@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.pricing-page.old-pricing {
  flex: 1;
  position: relative;
  background: #F7F7FF;
  padding-bottom: 100px;
    
  &::before {
    content: '';
    min-height: 210px;
    background: linear-gradient(
115.99deg, #620c90 11.95%, #4881fd 87.26%);
    position: relative;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  .pricing-table-wrapper {
    margin-top: 60px;
  }

  &.website-mode {
    background: none;

    &::before {
      display: none;
    }

    .pricing-table-wrapper {
      margin-top: 100px;
    }
  }
  
  // Cycle selector
  .select-cycle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  
    .cycle-selector {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
  
      @media screen and (max-width: $breakpoint-4) {
        gap: 10px;
      }
  
      h4 {
        font-family: Spartan;
        font-size: 20px;
        font-weight: 700;
        opacity: 0.5;
        color: #ffffff;
        // text-align: center;
        align-self: flex-start;
        padding-top: 2.4rem;
  
        transition: 0.1s;
  
        &.active {
          opacity: 1;
        }
  
        @media screen and (max-width: $breakpoint-4) {
          font-size: 17px;
        }
      }
    }
  
    .switch {
      position: relative;
      display: inline-block;
      width: 105px;
      height: 34px;
    }
    
    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        113.11deg,
        rgba(255, 255, 255, 0.8) 0.6%,
        rgba(255, 255, 255, 0.1) 99.61%
      );
    
      outline: 3px solid #ffffff1a;
      outline-offset: -3px;
      border-radius: 12px;
      width: 104px;
      height: 55px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
    
      right: 10px;
      bottom: 7px;
    
      width: 37px;
      height: 35px;
      background: #620c90;
    
      border: 3px solid #6e1f9985;
    
      border-radius: 50%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #5b5b5b;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(-42px);
      -ms-transform: translateX(-42px);
      transform: translateX(-42px);
    }

    h4 {
      position: relative;
      text-transform: capitalize;
    }

    .slider-save {
      position: absolute;
      left: 50%;
      top: 7px;
      font-size: 10px;
      color: #4780fc;
      display: block;
      transform: translateX(-50%);
      background: #fff;
      border-radius: 50em;
      padding: 7px 7px 4px;
      line-height: 1;
      width: fit-content;
      white-space: nowrap;
    }
  }
  
  // Pricing table
  .pricing-table-wrapper {
    margin: 30px auto 100px;
    align-items: center;
    font-family: Spartan, sans-serif;
  
    .pricing-table {
      gap: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      margin: 0;
  
      .mini-box {
        position: relative;
        text-align: center;
        background: #FAFAFC;
        color: $black-20;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 10px;
        // margin: 0 7px 20px;
        padding: 20px 13px 20px 13px;
        font-size: 14px;
        box-sizing: border-box;
        width: 342px;
        max-width: 342px;
        min-height: fit-content;
        // flex: 1;
  
        flex: 16rem;
  
        @media screen and (max-width: $breakpoint-4) {
          min-width: 270px;
        }
  
        &.pro, &.premium {
          padding: 40px 13px 40px 13px;
          box-shadow: 0px 0px 42.9338px rgba(255, 69, 114, 0.25);
          width: 385px;
          max-width: 385px;
          min-height: fit-content;
  
          .title {
            color: $pink-10;
            font-size: 25px;
          }
  
          .checkout-buttons-wrapper .button {
            background: $pink-10;
            opacity: 1;
            border: 2px solid $pink-10;
            color: #f7f7ff;
            transition: all 0.2s ease-in-out;
  
            &:disabled {
              opacity: 0.3;
            }
  
            &:not(:disabled):hover {
              background-color: #f92157;
              border: 2px solid #f92157;
              color: #f7f7ff;
            }
          }
        }
  
        .title {
          font-size: 25px;
          font-weight: 600;
          color: #4c73f0;
          line-height: 1.5;
        }
  
        .price {
          padding: 0 0 30px;
          margin: 10px 0 0;
          position: relative;
          font-family: Spartan;
  
          font-weight: 700;
          font-size: 35px;
          line-height: 135%;
  
          text-align: center;
          color: #1c3144;
  
          @media screen and (max-width: $breakpoint-4) {
            margin: 25px 0 0;
            padding: 0 0 20px;
          }
  
          span {
            font-size: 14px;
            font-weight: 200;
            color: #1c3144;

            @media screen and (max-width: $breakpoint-4) {
              font-size: 12px;
            }
          }
  
          .after-discount {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            text-decoration: none;
            color: #9095a5;
          }
        }
  
        .per-month-txt {
          font-size: 16px;
          margin: 0;
          line-height: 135%;
          font-weight: normal;
          color: #1c3144;
        }
  
        .checkout-buttons-wrapper .button {
          margin: 30px 0 40px 0;
          color: #1c3144;
          background: #f7f7ff;
          position: relative;
          transition: all 0.2s ease;
  
          opacity: 0.5;
          border: 2px solid #1c3144;
          box-sizing: border-box;
  
          font-size: 16px;
          border-radius: 10px;
          padding: 11px 30px 11px 30px;
          font-weight: 500;
          box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  
          &:hover {
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
            transform: translateY(4px);
            border: 2px solid $pink-10;
            color: $pink-10;
            opacity: 1;
          }
  
          &:disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
  
        .see-all-button {
          margin-top: 5px;
          font-size: 12px;
        }
  
        main {
          > div {
            display: flex;
            // align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            // text-align: center;
            position: relative;
            color: #1c3144;
  
            .feature-display {
              font-weight: 300;
              font-size: 16px;
              line-height: 22.4px;
  
              &.unchecked {
                text-decoration: line-through;
                text-decoration-thickness: 2px;
              }
            }
  
            .tooltip {
              margin-left: 5px;
              transform: scale(0.9);
              z-index: 10;
            }
  
            .tooltip-pointer {
              background: none;
              color: #4C4FFF;
              border: 1px solid #4C4FFF;
              transition: all 0.2s ease;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
  
              &:hover {
                background-color: #4C4FFF;
                color: #fff;
              }
            }
  
            .tooltip-content {
              z-index: 99;
              color: var(--lead-text);
              font-size: 12px;
              font-weight: 400;
              background-color: #f0f0f0;
              width: 290px;
              border-radius: 10px;
              
              &::before {
                border-top-color: #f0f0f0;
              }
            }
  
            .value {
              font-weight: 600;
              font-size: 16px;
              text-align: center;
              margin-right: 5px;
  
              i,
              svg {
                color: #4C4FFF;
                margin-right: 3px;
  
                &.fa-times {
                  color: #e0e3ee;
                }
              }
            }
          }
        }
      }
    }
  }
  
  // Full comparison table
  .full-comparison-table-wrapper {
    font-family: Spartan, sans-serif;
    display: flex;
    flex-direction: column;
  
    .full-plan-btn {
      cursor: pointer;
      align-self: center;
      margin: 0 0 120px;
  
      padding: 15px 30px;
  
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #4881fd;
      background: #efefff;
      border-radius: 12px;
  
      border: none;
  
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      transition: all 0.2s;
  
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 12px;
        padding: 2px;
        background: linear-gradient(72.63deg, #4881fd 28.76%, #ff4572 99.74%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  
      &:hover {
        transform: translateY(4px);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
      }
  
      &.browser-safari {
        border: 2px solid #4881fd;
      }
    }
  
    .full-comparison-table-overflow {
      max-width: 100%;
      overflow-x: auto;
      border-radius: 12px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
      height: fit-content;
      opacity: 1;
    }
  
    .full-comparison-table {
      color: black;
      border-radius: 12px;
      width: 100%;
      min-width: 1000px;
      margin: auto;
  
      header {
        display: flex;
        align-items: stretch;
        width: 100%;
        justify-content: center;
        background-color: #f7f7ff;
  
        &:first-child {
          border-radius: 12px 12px 0 0;
        }
  
        > * {
          flex: 1 0;
          padding: 38px 20px;
        }
  
        .group-title {
          text-align: center;
          margin: 0;
          font-weight: bold;
          box-sizing: border-box;
          position: sticky;
          left: 0;
          background-color: #f7f7ff;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
  
          h4 {
            line-height: 1.5;
            font-size: 25px;
            margin: 0;
          }
  
          @media screen and (max-width: $breakpoint-4) {
            position: unset;
          }
        }
  
        .plan-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
  
          &.Pro {
            .title {
              color: $pink-10;
            }
            button {
              background: $pink-10;
              color: #f7f7ff;
              border: 2px solid $pink-10;
              opacity: 1;
  
              &:hover {
                background-color: #f92157;
                border: 2px solid #f92157;
                color: #f7f7ff;
              }
            }
          }
  
          .title {
            margin: 0;
            font-weight: bold;
            font-size: 20px;
            color: #4881fd;
          }
  
          .price {
            position: relative;
            font-weight: 700;
            font-size: 20px;
            color: #000;
  
            @media screen and (max-width: $breakpoint-2) {
              font-size: 18px;
            }

            span {
              font-weight: 500;
              font-size: 14px;
            }
  
            .after-discount {
              // position: absolute;
              font-size: 11px;
              color: $gray-20;
              font-weight: 400;
              // top: 1rem;
              // right: 50%;
              display: block;
              // transform: translateX(50%);
              text-align: center;
              text-decoration: none;
              padding: 5px 0 0;
            }
          }

          button {
            color: #1c3144;
            font-weight: 500;
            font-size: 16px;
            opacity: 0.5;
            padding: 9px 25px 9px 25px;
            border: 2px solid #1c3144;
            border-radius: 12px;
  
            transition: 0.2s;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  
            &:hover {
              box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
              transform: translateY(4px);
              opacity: 1;
              color: $pink-10;
              border: 2px solid $pink-10;
            }
          }
        }
      }
    }
  
    .pricing-table-content-wrapper {
      background-color: #efefff;
      padding: 3.6rem 0 1.7rem 0;
      width: 100%;
  
      &:last-child {
        border-radius: 0 0 12px 12px;
      }
  
      .pricing-table-content {
        display: flex;
        margin-bottom: 20px;
        width: 100%;
        align-items: stretch;
        justify-content: space-between;
        // gap: 20px;
  
        > * {
          flex: 1 0;
        }
  
        // gap: 5rem;
  
        .feature-name {
          text-align: center;
          font-weight: 500;
          line-height: 22px;
          font-size: 16px;
          color: #000;
          box-sizing: border-box;
          position: sticky;
          left: 0;
          background-color: #efefff;
          z-index: 1;
  
          @media screen and (max-width: $breakpoint-4) {
            position: unset;
          }
        }
  
        .feature-value {
          text-align: center;
          font-weight: 300;
          font-size: 16px;
  
          svg {
            transform: scale(0.9);
          }
  
          @media screen and (max-width: $breakpoint-4) {
            font-size: 15px;
          }
        }
      }
    }
  }
}