.styled-media-manager-files {
  text-align: center;

  .styled-media-manager-total {
    position: sticky;
    top: 0;
    z-index: 3;
    padding: 20px 0 0;
  }

  .media-manager-content-items {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    height: fit-content;
    text-align: left;
  }

  .loader.load-more {
    position: relative;
    left: auto;
    top: auto;
    transform: scale(0.7);
    margin: 10px;
  }
}