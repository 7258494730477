.date-picker-wrapper {
	position: relative;

	.calender-icon {
		position: absolute;
		left: 15px;
		top: 9px;
		bottom: 8px;
		color: #babec1;
		pointer-events: none;
	}

	#date-picker {
		padding: 4px 15px 5px 35px;
		font-size: 14px;
		font-weight: 300;
		color: #0c0f21;
		border: 1px solid #babec1;
		font-family: 'Lato';
		height: 30px;
		cursor: pointer;
		position: relative;

		.rmdp-day {
			min-width: 100px;
		}
	}
	
	.rmdp-container {
		width: 100%;
	}
}

#stupid-date-picker {
	z-index: 99999;
	position: absolute;
	top: 0;

	.rmdp-wrapper {
		.rmdp-year-picker,
		.rmdp-month-picker {
			.rmdp-today {
				span {
					background-color: #3c5ad8;
					&:hover {
						background-color: #3c5ad8;
						color: white;
					}
				}
			}

			.rmdp-selected {
				span {
					background-color: #6881e9;
					&:hover {
						background-color: #6881e9;
						color: white;
					}
				}
			}

			span {
				background-color: white;
				&:hover {
					background-color: #eef6ff;
					color: #0c0f21;
				}
			}
		}

		.rmdp-arrow-container {
			&:hover {
				background-color: transparent;
				box-shadow: none;
			}

			.rmdp-arrow {
				border: solid #0c0f21;
				border-width: 0 2px 2px 0;
			}
		}

		.rmdp-week {
			.rmdp-week-day {
				color: #0c0f21;
			}

			.rmdp-day {
				&.rmdp-range {
					background-color: #6881e9;
					box-shadow: none;
				}

				&.rmdp-today {
					span {
						background-color: #3c5ad8;
					}
				}
			}

			.rmdp-day:not(.rmdp-range):not(.rmdp-today) span {
				border-radius: 4px;
				&:hover {
					background-color: #eef6ff;
					color: #0c0f21;
				}
			}
		}
	}
}
