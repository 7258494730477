.copy-input-wrapper {
  position: relative;

  .copy-btn {
    position: absolute;
    right: 0;
    top: 50%;
    background: none;
    border: none;
    font-family: Lato;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5em;
    color: var(--colorDarkGraphPrimary);
    padding: 2px 15px 5px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .copy-input-post-message {
    position: absolute;
    right: 0;
    bottom: 100%;
    font-size: 12px;
    background: var(--backgroundMenu, #000);
    color: #fff;
    padding: 5px 15px;
    border-radius: 3px;
    margin-bottom: 5px;
    z-index: 10;
  }
}
