@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.project-settings {
	position: relative;

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		max-width: 600px;
		margin-bottom: 30px;

		@media (max-width: $breakpoint-3) {
			max-width: 100%;
		}

		.page-titles {
			padding-bottom: 0px;
		}

		button {
			margin-left: auto;
		}
	}

	.project-settings {
		max-width: 600px;
		background-color: white;
		padding: 30px 30px 10px;
		border-radius: 10px;
		border: 1px solid #F0F0F0;

		@media (max-width: $breakpoint-3) {
			max-width: 100%;
		}
	}
	
	.delete-project {
		margin-top: 30px;
		max-width: 600px;
	}

	.no-access-box {
		max-width: 930px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 30px;
		background: #ffffff;
		border-radius: 10px;
		padding: 73px 20px;

		p {
			font-weight: 300;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			max-width: 300px;
		}
	}
}
