@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.analytics-wrapper {
  position: relative;

  &.main-section, &.main-section > header {
    background-color: transparent;
  }

  .content-wrapper {
    max-width: 100%;
    padding: 40px;
  }

  .back-button {
    margin: 0 0 10px;
    color: $black-20;
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    span {
      margin-left: 5px;
    }

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  .page-titles {
    h2 {
      margin: 0 0 5px;

      span {
        font-size: 14px;
      }
    }

    h3 {
      margin-top: -10px;

      @media screen and (max-width: $breakpoint-3) {
        margin-top: 0;
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: $breakpoint-3) {
      flex-direction: column;
      align-items: center;
    }
  }

  .flex-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-3) {
      flex-direction: column;
    }

    > * {
      flex: 1;
      box-sizing: border-box;
    }
  }

  section {
    margin-bottom: 60px;
    padding: 0 20px;

    @media screen and (max-width: $breakpoint-3) {
      padding: 0;
    }
  }

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 20px;
    text-transform: uppercase;
  }

  .date-picker-wrapper {
    position: relative;
    margin: 0 40px 40px 10px;
    padding: 0;
    display: flex;
    align-items: center;

    h3 {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  .boxes {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .box {
    margin: 0 20px 40px;
    background: rgba(255, 255, 255, 0.7);
    padding: 40px;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    flex-basis: 250px;
    position: relative;
    text-align: center;
    font-weight: 300;
    color: $gray-20;

    .tooltip-wrapper {
      position: relative;

      .tooltip-toggler {
        cursor: default;
        border-radius: 50em;
        background: $black-20;
        font-size: 10px;
        width: 20px;
        height: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        font-weight: 700;
        color: #fff;
        transform: translateY(-5px) scale(0.8);

        &:hover {
          + .local-tooltip {
            display: block;
          }
        }
      }

      .local-tooltip {
        width: 200px;
        max-width: 200px;
        font-size: 12px;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 60px;
      color: $black-20;
      margin: 0;
      line-height: 1em;
    }

    p {
      margin: 20px 0 0;
      padding: 0;
      font-size: 14px;
      line-height: 1em;
    }
  }

  .table-wrapper {
    width: 100%;
    overflow: auto;
    max-width: 100%;
    max-height: 400px;
    margin: 0 auto;

    table {
      border-collapse: collapse;
      width: 100%;
      // table-layout: fixed;
      background: #fff;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    }

    tr {
      border-bottom: 1px solid #f2f2f2;

      &.unread {
        font-weight: 700;
      }
    }

    th {
      padding: 10px 20px;
      font-size: 14px;
      color: #333333;
      line-height: 1.4;
      cursor: default;
      font-weight: 700;
      text-align: left;

      &.center {
        text-align: center;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.015);
      }
    }

    td {
      color: #999999;
      padding: 15px;
      text-align: left;
      font-size: 13px;
      vertical-align: middle;

      &.center {
        text-align: center;
      }
    }
  }
}

.react-daterange-picker {
  display: inline-flex;
  position: relative;
  margin-left: 20px;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-weight: 300;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  &--disabled {
    background-color: rgb(240, 240, 240);
    color: rgb(109, 109, 109);
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: none;
  }

  &__inputGroup {
    $digit-width: 0.54em;
    $dot-width: 0.217em;
    // own padding + inputs padding + digits width + dots width
    min-width: calc((4px * 3) + #{$digit-width} * 8 + #{$dot-width} * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;

    &__divider {
      padding: 1px 0;
      white-space: pre;
    }

    &__input {
      min-width: $digit-width;
      height: 100%;
      position: relative;
      padding: 0 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:invalid {
        background: fade(red, 10%);
      }

      &--hasLeadingZero {
        margin-left: -$digit-width;
        padding-left: calc(1px + #{$digit-width});
      }
    }
  }

  .react-daterange-picker__clear-button {
    display: none;
  }

  &__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;

    &:enabled {
      cursor: pointer;

      &:hover,
      &:focus {
        .react-daterange-picker__button__icon {
          stroke: rgb(0, 120, 215);
        }
      }
    }

    &:disabled {
      .react-daterange-picker__button__icon {
        stroke: rgb(109, 109, 109);
      }
    }

    svg {
      display: inherit;
    }
  }

  &__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;

    &--closed {
      display: none;
    }

    .react-calendar {
      border-width: thin;
    }
  }
}
