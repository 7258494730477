@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.account {
  position: relative;
  
  .content-wrapper {
    padding: 40px;
  }

  .btn {
    margin-top: 20px;
  }
  
  .form-row {
    input[type=text], input[type=password] {
      width: 320px;

      @media screen and (max-width: $breakpoint-3) {
        width: 100%;
      }
    }

    input[type=password] {
      padding-right: 60px;
    }
  }

  .ReactPasswordStrength {
    width: 320px;
    border: none;
    position: relative;

    @media screen and (max-width: $breakpoint-3) {
      width: 100%;
    }

    .ReactPasswordStrength-strength-desc {
      padding: 13px 5px;
      width: auto;
      font-size: 13px;
    }

    .ReactPasswordStrength-strength-bar {
      position: absolute;
      bottom: -1px;
      left: 1px;
      border-radius: 5px;
    }

    .ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
      width: calc(100% + -2px);
    }
  }
}