  .danger-zone {
    border: 1px solid var(--colorWarning);
    border-radius: 10px;
    padding: 20px 30px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      color: var(--colorWarning);
    }
    display: flex;
    flex-direction: column;
    .button {
      background: var(--colorWarning);
      margin-left: auto;
      align-self: flex-end;
      color: var(--colorPureWhite);
      &:hover {
        background-color: var(--colorWarningHover);
      }
    }
  }