.card {
	background-color: white;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	width: fit-content;
	padding: 20px 30px;

	@media screen and (max-width: 370px) {
		padding: 20px;
	}
}
