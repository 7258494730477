.user-details {
	max-width: 930px;

	.subtitle {
		display: flex;
		align-items: flex-end;
		gap: 10px;
	}

	.number-stats-container {
		display: flex;
		gap: 30px;
		margin: 30px 0;
		flex-wrap: wrap;
		justify-content: center;
	}

	.advanced-user-info {
		display: flex;
		gap: 30px;
		margin-bottom: 30px;

		@media screen and (max-width: 1100px) {
			flex-direction: column;
		}

		.timeline-container {
			flex: 1;
			width: 100%;

			.event-wrapper {
				display: flex;
				flex-direction: column;
				gap: 20px;
				margin-top: 20px;

				.event {
					display: flex;
					justify-content: space-between;
					gap: 10px;
					flex-wrap: wrap;
					color: #141414;
					align-items: center;

					&:last-of-type {
						.event-icon {
							&::before {
								height: 0;
							}
						}
					}

					.title-wrapper {
						display: flex;
						align-items: center;
						gap: 20px;

						.event-icon {
							position: relative;

							&:not(.last) {
								&::before {
									content: '';
									position: absolute;
									left: 50%;
									top: calc(100% - 5px);
									height: 24.5px;
									border-left: 4px solid #6881e9;
									transform: translateX(-2px);
								}
							}
						}

						h2 {
							margin: 0;
							font-weight: 600;
							font-size: 14px;
						}
					}
				}
			}
		}

		.tables-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 30px;

			.cancel-icon {
				font-size: 20px;
				display: flex;
				align-items: center;
			}

			.data-table-card.user-info-table {
				margin: 0;

				.inner-table-wrapper {
					overflow-y: auto;
				}

				.no-items-container {
					margin-top: 10px;
					gap: 10px;

					svg {
						width: 100px;
					}
				}

				.bottom-wrapper {
					margin-top: 0;
				}

				.loading-wrapper {
					max-height: 80px;
				}
			}
		}
	}

	.notes-section {
		width: 100%;
		margin-bottom: 30px;

		.notes-container {
			margin-top: 20px;
			display: flex;
			gap: 30px;
			flex-wrap: wrap;

			.note {
				display: flex;
				flex-direction: column;
				max-width: 270px;
				flex: 170px;
				border-radius: 10px;
				background-color: #eef6ff;
				padding: 10px 20px;
				min-height: 100px;
				position: relative;

				.actions {
					position: absolute;
					right: 10px;
					display: flex;
					gap: 7px;
					align-items: center;

					.icon {
						cursor: pointer;
						color: #747b95;
						transition: 0.2s;
						font-size: 20px;

						&.edit {
							font-size: 17px;
						}

						&:hover {
							color: #4e525f;
						}
					}
				}

				p {
					font-weight: 300;
					font-size: 14px;
					color: #141414;
				}
			}

			.add-item-card {
				cursor: pointer;
				width: 270px;
				min-height: 105px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #b4b4b4;
				border: 2px dashed #b4b4b4;
				border-radius: 10px;
				font-weight: 600;
				font-size: 14px;
				height: 100px;
				transition: 0.2s;

				&:hover {
					color: #838383;
					border: 2px dashed #838383;
				}
			}
		}
	}
}

.cancel-subscription-popup {
	.refund-content {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.box-select-container {
			display: flex;
			gap: 20px;
			flex-wrap: wrap;

			.box {
				cursor: pointer;
				border: 1px solid #b4b4b4;
				color: #141414;
				padding: 20px;
				border-radius: 5px;
				flex: 140px;
				transition: 0.2s;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.suffix {
					font-weight: 400;
					display: flex;

					&.flex-end {
						align-self: flex-end;
					}
				}

				.number-container {
					align-items: center;
					display: flex;
					gap: 15px;
					height: 10px;
					margin-top: 20px;

					.number-select {
						max-width: 80px;
						input {
							background: transparent;
						}
					}
				}

				.checked {
					position: absolute;
					right: 10px;
					font-size: 20px;
					top: 10px;
					color: #6881e9;
					opacity: 0;
				}

				&:hover {
					border: 1px solid #6881e9;
				}

				h4 {
					margin: 0 0 7px;
					font-weight: 600;
					font-size: 14px;
				}

				p {
					margin: 0;
					font-weight: 300;
					font-size: 14px;
				}

				&.active {
					border: 1px solid #6881e9;

					.checked {
						opacity: 1;
					}
				}
			}
		}

		p {
			margin: 0;
		}

		.amount-wrapper {
			display: flex;
			align-items: center;
			gap: 20px;
			font-weight: 600;
			font-size: 12px;
			color: #1e2a3b;
		}

		.checkbox-wrapper {
			display: flex;
			align-items: center;
			gap: 10px;

			.cancel-subscription-text {
				cursor: pointer;
			}
		}
	}
}
