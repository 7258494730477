.plan-prices {
  .app-cards {
    .app-card {
      background: #eef6ff;
      height: 120px;
      font-size: 16px;
      box-shadow: none;

      .app-card-footer {
        color: #6e7482;
      }
    }
    .app-card-item {
      position: relative;
      padding-left: 50px;
    }
    .app-card-add {
      border: dashed 2px var(--colorIconGrey);
      color: var(--colorIconGrey);
      background-color: var(--colorPureWhite);
    }
  }
}
