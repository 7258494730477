.developer-page {
  .feature-editor {
    .input-container {
      label {
        width: 100%;
      }
    }

    .inputs-horizontal .number-input + .buttons-container {
      bottom: 0;
    }
  }
}