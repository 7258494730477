@import "~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars";

.widgets {
  position: relative;

  > .inner,
  .discover-plugins {
    max-width: 1300px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-3) {
      flex-direction: column;
      padding-bottom: 40px;
    }
  }

  .no-results {
    max-width: 100%;
    padding: 0 20px;
    background-color: white;
    border: 1px solid #f0f0f0;
    padding: 50px 20px;
    border-radius: 10px;

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #141414;
      margin-top: 30px;
      padding-left: 20px;
    }
    .buttons-wrapper {
      margin: 40px 0;
    }
  }

  .components-wrapper {
    padding: 30px 30px 0;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    color: #1b1b1b;
    max-width: 1300px;

    @media screen and (max-width: 1130px) {
      overflow: auto;
    }

    .top {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 240px;
      grid-gap: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 0 15px 15px;

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        min-width: 70px;
      }
    }

    .components {
      .component {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 240px;

        grid-column-end: 220px;
        grid-gap: 20px;
        padding: 15px;

        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }

        p {
          font-weight: 300;
          font-size: 14px;
          margin: 0;
          display: flex;
          align-items: center;
          min-width: 70px;
        }

        img {
          width: 130px;
          height: 70px;
          object-fit: cover;
          cursor: pointer;
        }

        .app-name {
					display: block;
					align-self: center;

					.plan-name {
						background: var(--colorDarkGraphPrimary);
						color: #fff;
						border-radius: 50em;
						padding: 3px 10px;
						cursor: default;
						font-size: 12px;
						margin-left: 8px;
					}
        }

        .views {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .upgrade-text {
            display: inline-flex;
            align-items: center;
            gap: 5px;
            color: #6881e9;
            font-weight: 500;
            font-size: 12px;
            cursor: pointer;
          }

          p {
            margin: 0;
            font-weight: 300;
            font-size: 16px;

            span {
              font-weight: 500;

              &.red {
                color: #f53939;
              }
            }
          }
        }
        .actions {
          display: flex;
          align-items: center;
          gap: 20px;

          .options {
            padding: 6px;
            margin-left: -15px;
            .icon {
              font-size: 24px;
              color: #6881e9;
            }
          }
        }
      }
    }
  }

  .search-components {
    display: flex;
    flex-wrap: nowrap;

    .input-container {
      input,
      select {
        min-height: 33px;
      }

      input {
        padding-left: 40px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.34938 1.60176C7.21356 -0.533921 3.73735 -0.533921 1.60152 1.60176C-0.53384 3.73791 -0.53384 7.21343 1.60152 9.34958C3.50353 11.251 6.46581 11.4547 8.60025 9.96996C8.64516 10.1825 8.74795 10.3852 8.91324 10.5505L12.0237 13.6608C12.477 14.1131 13.2094 14.1131 13.6604 13.6608C14.1132 13.208 14.1132 12.4756 13.6604 12.0242L10.5499 8.913C10.3856 8.7491 10.1823 8.64586 9.96981 8.60095C11.4556 6.46619 11.2519 3.50457 9.34938 1.60176ZM8.36736 8.36761C6.77278 9.96209 4.17766 9.96209 2.58355 8.36761C0.989897 6.77314 0.989897 4.17866 2.58355 2.58419C4.17766 0.990178 6.77278 0.990178 8.36736 2.58419C9.96194 4.17866 9.96194 6.77314 8.36736 8.36761Z' fill='%23B4B4B4'/%3E%3C/svg%3E%0A");
        background-position: 15px 50%;
        background-repeat: no-repeat;
      }
      &:first-child:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 20px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: var(--colorDividerGrey);
      }
    }
    .input-container:nth-child(2) select {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    > * {
      margin: 0 10px;
      color: $black-20;
    }

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      line-height: 1em;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      i {
        margin: 4px;
      }
    }
  }

  .discover-plugins {
    h3 {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      text-transform: capitalize;
      color: #0c0f21;
      margin-bottom: 30px;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding: 30px 0 0;
      margin: 30px 15px 30px;
    }

    .plugin-listing-wrapper {
      justify-content: center;
      gap: 50px;
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px 30px;
      background-image: #fafafc;

      .plugin-listing {
        display: flex;
        flex-basis: calc(25% - 50px);
        border-radius: 15px;
        background-color: #fff;
        border: 1px solid #f0f0f0;
        position: relative;
        overflow: hidden;

        @media (max-width: 2000px) {
          flex-basis: calc(33% - 50px);
          div.plugin-listing-image {
            min-width: 200px;
            flex-basis: calc(50% - 50px);
          }
        }
        @media (max-width: 1500px) {
          flex-basis: calc(50% - 50px);
        }
        @media (max-width: 1100px) {
          flex-basis: 100%;
          div.plugin-listing-image {
            flex-basis: 40%;
          }
          div.plugin-meta {
            padding: 0px;
            text-align: left;

            h2 {
              font-size: 20px;
            }
            p {
              font-size: 16px;
            }
          }
        }
        @media (max-width: 520px) {
          display: block;

          div.plugin-listing-image {
            flex-basis: 0%;
            height: 150px;
            margin: 0;
            padding: 0;

            img {
              object-position: center;
            }
          }

          div.plugin-meta {
            margin: 0;
            padding: 0 20px 20px;
          }
        }
        &:hover {
          .plugin-listing-actions {
            opacity: 1;

            &:before {
              opacity: 0.85;
              z-index: 0;
            }
          }
        }

        .plugin-listing-image {
          height: 100%;
          padding-right: 25px;
          flex-basis: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            width: 100%;
          }

          img {
            height: 75%;
            object-fit: contain;
            object-position: center;
          }
        }

        .plugin-meta {
          margin: auto 0;
          padding: 25px 25px 25px 0;
          p {
            font-size: 14px;
          }
          h2 {
            font-weight: 500;
            font-size: 18px;
          }
        }

        .plugin-listing-actions {
          opacity: 0;
          transition: opacity 0.2s ease-in-out;

          .action {
            cursor: pointer;
            position: absolute;
            inset: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--colorDarkGraphPrimary);
            text-decoration: none;

            svg {
              margin-right: 10px;
            }
            span {
              text-align: center;
              font-size: 18px;
              font-weight: 500;
            }
          }

          &:before {
            transition: opacity 0.2s ease-in-out;
            border-radius: 10px;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--colorPureWhite);
          }
        }
      }
    }

    .button-wrapper {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: rgba(255, 255, 255, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.2s ease;

      @media screen and (max-width: $breakpoint-3) {
        position: relative;
        background: none;
        height: auto;
        opacity: 1;
        margin-top: 20px;
      }

      .button {
        transform: scale(0.8);
        text-decoration: none;

        @media screen and (max-width: $breakpoint-3) {
          padding: 20px 30px;
        }
      }
    }

    .plugin-meta {
      text-align: left;

      @media screen and (max-width: $breakpoint-3) {
        text-align: center;
      }

      h4 {
        text-align: left;
        margin: 0 0 5px;
        color: $black-10;

        @media screen and (max-width: $breakpoint-3) {
          text-align: center;
        }
      }

      p {
        margin: 0;
        font-family: $secondaryFont;
        color: $black-40;
      }
    }
  }

  .popup.popup-dashboard {
    .popup-content {
      border: none;
      width: 600px;

      h2 {
        color: #141414;
        background: transparent;
        padding-bottom: 10px;
      }

      h4 {
        font-weight: 500;
        strong {
          font-weight: inherit;
        }
      }
      .content {
        margin: 0 13px;
        border-top: 1px solid var(--colorDividerGrey);
        padding: 20px 17px 0;
        .input-container .copier {
          background: transparent;
        }

        .horizontal {
          .input-container {
            label {
              padding: 5px 20px 5px 0;
              width: auto;
            }
            .copier {
              border: none;
            }
          }
        }
        .buttons-wrapper {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          margin-top: 20px;
          gap: 10px;
          padding-bottom: 20px;
          .button {
            margin: 0;
          }
        }
      }
      button.close-popup {
        top: 20px;
        right: 15px;
        color: var(--colorIconGrey);
      }

      background-color: var(--colorPureWhite);
    }
  }

  .code-popup {
    .popup-content {
      width: 680px;
      padding: 0;

      > h2 {
        margin: 0 0 20px;
        padding: 20px;
        background: #eaecf3;
      }

      .content {
        padding: 10px 40px 20px;
      }

      article {
        margin-bottom: 20px;
      }

      h4 {
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          background: $black-20;
          width: 20px;
          height: 20px;
          font-weight: 700;
          text-align: center;
          color: #fff;
          border-radius: 50em;
          margin-right: 7px;
        }
      }

      textarea {
        overflow: auto;
        height: 85px !important;
        max-height: 156px !important;
        resize: none;
        width: 100% !important;
        max-width: 100% !important;
        font-size: 14px;
        font-family: $secondaryFont;
        font-weight: 200;
        padding: 4px !important;

        &.div-value {
          height: 47px !important;
          max-height: 47px !important;
          min-height: 47px !important;
        }
      }
    }
  }

  .delete-popup {
    .popup-content {
      width: 520px;
      padding: 0;

      > h2 {
        margin: 0 0 20px;
        padding: 20px;
        background: #eaecf3;
      }

      .content {
        padding: 10px 40px 30px;
      }

      .buttons-wrapper {
        margin-top: 40px;
        padding: 0;

        > * {
          margin: 0 10px;
        }
      }
    }
  }

  .duplicate-popup {
    .popup-content {
      width: 520px;
      padding: 0;

      > h2 {
        margin: 0 0 20px;
        padding: 20px;
        background: #eaecf3;
      }

      .content {
        padding: 10px 40px 30px;
      }

      .buttons-wrapper {
        margin-top: 40px;
        padding: 0;

        > * {
          margin: 0 10px;
        }
      }
    }
  }

  .popup.popup-dashboard.permissions-popup {
    .popup-content {
      width: 740px;
    }
  }
}
