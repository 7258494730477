.popup.mini-bundles-app-selection {
  --lead: #6278d4;

  .popup-content {
    width: 1340px;
    height: 700px;
    background-color: #fff;
    font-family: Popins, sans-serif;
    color: #1e2a3b;
    top: 80px !important;
    transform: translate(-50%, 0) !important;

    h2 {
      font-weight: 600;
      font-size: 18px;
      color: inherit;
      background-color: #fff;
      position: sticky;
      top: 0;
      z-index: 3;

      span {
        margin-left: 20px;
        font-size: 90%;
        font-weight: 300;

        b {
          font-weight: 600;

          &.error {
            color: #e96881;
          }
        }
      }
    }

    .error-message {
      background: #fff7f8;
      border: 1px solid #ffc9d5;
      border-radius: 5px;
      padding: 20px;
      margin: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button {
        background-color: var(--lead);
        color: #fff;
        border: none;
        gap: 10px;
        font-size: 16px;

        &:hover {
          background-color: #465cb4;
        }
      }
    }

    > section {
      padding: 20px;
      background: none;
    }
  }

  .main-content-wrapper {
    padding: 0;
    margin: 0;

    .categories .cat,
    .categories .cat-title,
    .categories .divider {
      display: none;
    }

    .categories .cat:nth-child(1),
    .categories .cat:nth-child(2),
    .categories .cat:nth-child(5) {
      display: flex;
    }

    // .filter-container {
    //   width: 100%;
    // }
  }

  .widget-catalog .main-content-wrapper .apps-container {
    align-items: stretch;
  }
  
  .widget-catalog .main-content-wrapper .apps-container .app {
    max-width: 330px;

    .select-overlay {
      position: absolute;
      z-index: 1;
      height: 170px;
      background-color: rgba(0, 0, 0, 0.75);
      width: 100%;
      border-radius: 5px 5px 0 0;
      transition: 0.2s;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 18px;
    }

    svg {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
      color: var(--lead);
      font-size: 20px;
      transition: 0.2s;
      opacity: 0;
    }

    &:hover {
      border-color: var(--lead);

      .select-overlay {
        opacity: 1;
      }
    }

    &.selected {
      border-color: var(--lead);
      background-color: #f2f5ff;

      svg {
        opacity: 1;
      }
    }
  }

  .mini-bundles-app-selection-footer {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    z-index: 5;

    .button {
      border: none;
    }

    .button:not(.transparent) {
      background-color: var(--lead);
      color: #fff;
      border: none;
      gap: 10px;
      font-size: 16px;

      &:hover {
        background-color: #465cb4;
      }
    }
  }
}
