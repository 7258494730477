.plan-providers {
  .plan-provider-item {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    // align-items: center;
  }

  .app-cards {
    .app-card-item {
      height: auto;
    }

    .status {
      position: absolute;
      right: 7px;
      top: 7px;
      font-size: 20px;
    }
  }

  .disabled img {
    opacity: 0.5;
  }

  img {
    max-width: 100%;
    height: 30px;
    &.shift4shop {
      height: 25px;
    }
  }
}

.popup .popup-content {
  .plan-provider-details {
    font-size: 16px;
  }

  .plan-provider-details-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px;
  }

  .plan-provider-warning {
    line-height: 1.5em;
    padding: 20px;

    svg {
      font-size: 14px;
    }

    strong {
      margin: 0 5px;
    }
  }

  .plan-fields {
    margin: 20px 0;
    padding: 30px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    .form-row:last-child {
      margin-bottom: 0;
    }
  }
}
