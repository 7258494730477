.developer-page {
  .coupon-editor {
    .input-container {
      label {
        width: 100%;
      }
    }

    .inputs-horizontal .number-input + .buttons-container {
      bottom: 0;
    }

    .checkbox-wrapper {
      label {
        margin: 0;
        font-weight: 500;
      }
    }
  }
}