.payments-page {
	@media screen and (max-width: 1080px) {
		.app-section-spread {
			flex-direction: column-reverse;
		}
	}
	.app-section-spread {
		display: flex;
		gap: 30px;
		margin-bottom: 30px;

		> :nth-child(1) {
			flex: 1;
			margin: 0;
		}
		> :nth-child(2) {
			flex: 0.25;
			flex-basis: 150px;
		}
	}
	.app-cards {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 20px;
		gap: 30px;
		.app-card-content {
			word-wrap: break-word;
			overflow: hidden;
			-webkit-line-clamp: 3;
			padding-right: 10px;
		}
		&.disabled {
			opacity: 0.4;
			pointer-events: none;
		}
	}

	.app-card {
		flex-basis: 250px;
		max-width: 250px;

		@media screen and (max-width: 1300px) {
			flex-basis: 250px;
			max-width: 250px;
		}
	}
}
.editor-error {
	padding: 5px;
	color: rgba(0, 0, 0, 0.62);
	font-size: 1.1em;
	svg {
		width: 15px;
		margin-right: 10px;
	}
	// font-size: 0.9em;
}
.developer-page {
	.payments-input {
		padding: 5px 10px;
		width: 100%;
		border: #dce0e9 1px solid;
		border-radius: 4px;
		font-family: inherit;
		display: inline;
		transition: outline 0.15s ease, border-color 0.2s ease;
		box-sizing: border-box;
		&:focus {
			outline: #1eb2e760 solid 2px;
		}
		&:disabled {
			opacity: 0.3;
			pointer-events: none;
		}
	}
}
.developer-page .json-editor-wrapper {
	table {
		margin-bottom: 0;
	}

	.jsoneditor {
		border: 1px solid #b4b4b4;
		min-height: 100px;
		border-radius: 5px;
		padding: 10px 0;
		background-color: #fff;

		.jsoneditor-values {
			margin-left: 0 !important;
		}

		div.jsoneditor-tree {
			background: transparent;
			border-radius: 5px;
		}

		tr.jsoneditor-highlight,
		tr.jsoneditor-selected {
			background: #eef6ff;
		}
	}

	.jsoneditor-menu {
		margin: 0;

		li {
			margin: 0;
		}
	}
}
.app-card {
	display: flex;
	flex-direction: column;
	flex-basis: calc(33.3% - 20px);
	max-width: calc(33.3% - 20px);
	border-radius: 6px;
	height: 200px;
	padding: 24px 30px;
	box-shadow: var(--shadowPrimary);
}
@media screen and (max-width: 1300px) {
	.app-card {
		flex-basis: calc(50% - 20px);
		max-width: calc(50% - 20px);
	}
}
@media screen and (max-width: 1000px) {
	.app-card {
		flex-basis: calc(100% - 20px);
		max-width: calc(100% - 20px);

		.app-info {
			font-size: 0.85em;
		}
	}
}
.feature-editor {
	* {
		background-color: 0;
	}
	.app-section-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		// margin-bottom: 20px;
		padding: 0;
	}
}
.features {
	.app-card {
		height: 150px;
		.app-card-content {
			-webkit-line-clamp: 2;
		}
	}
	.app-card-item {
		padding-left: 45px;
		padding-right: 15px;
		position: relative;
	}
}
.app-card-add {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	color: var(--colorDarkGraphPrimary);
	background-color: var(--colorPureWhite);
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
	transition: color 0.2s ease-in-out;
	text-decoration: none;

	svg {
		margin-right: 5px;
	}
	&:hover {
		color: var(--colorActive);
	}
}
.app-card-item {
	background-color: #fcfcfd;
	transition: all 0.2s;
	font-size: 15px;

	.title {
		position: relative;

		h5 {
			color: #1e2a3b;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 150px;
			white-space: nowrap;
		}

		.label {
			position: absolute;
			left: 100%;
			bottom: 0;
			font-size: 12px;
			color: var(--colorPureWhite);
			font-weight: 300;
			margin-left: 10px;
			padding: 0 10px;
			border-radius: 50px;
			&.active {
				background-color: var(--colorDarkGraphPrimary);
			}
			&.inactive {
				background-color: var(--colorDarkGraphSecondary);
			}
		}
	}

	.app-card-header {
		display: flex;
		gap: 10px;
		align-items: flex-start;
		margin-bottom: 10px;
		h5 {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 150px;
		}
		// word-wrap: nowrap;

		.app-card-buttons {
			display: flex;
			margin-left: auto;
			height: 20px;
			.app-card-button {
				cursor: pointer;
				padding: 0 5px;
				color: #949494;
				border-radius: 50%;
				transition: color 0.2s ease;

				&:hover {
					color: var(--colorActive);
				}
				&.copy-icon {
					background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='3.02637' width='8.47368' height='8.47368' rx='0.815068' stroke='%23949494'/%3E%3Cpath d='M2.52612 7.2627H6.94718' stroke='%23949494'/%3E%3Cpath d='M4.73682 5.05273L4.73682 9.47379' stroke='%23949494'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.56421 1C2.70537 0.425864 3.2236 0 3.84129 0H10.6848C11.4111 0 11.9999 0.588776 11.9999 1.31507V8.15861C11.9999 8.82027 11.5113 9.3678 10.8752 9.46001V1H2.56421Z' fill='%23949494'/%3E%3C/svg%3E%0A");
					width: 13px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					border-radius: 0;
					&:hover {
						background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='3.02637' width='8.47368' height='8.47368' rx='0.815068' stroke='%233b54bc'/%3E%3Cpath d='M2.52612 7.2627H6.94718' stroke='%233b54bc'/%3E%3Cpath d='M4.73682 5.05273L4.73682 9.47379' stroke='%233b54bc'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.56421 1C2.70537 0.425864 3.2236 0 3.84129 0H10.6848C11.4111 0 11.9999 0.588776 11.9999 1.31507V8.15861C11.9999 8.82027 11.5113 9.3678 10.8752 9.46001V1H2.56421Z' fill='%233b54bc'/%3E%3C/svg%3E%0A");
					}
				}
			}
		}

		.app-card-handle {
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='%23949494'/%3E%3Ccircle cx='9.99988' cy='2' r='2' fill='%23949494'/%3E%3Ccircle cx='2' cy='10' r='2' fill='%23949494'/%3E%3Ccircle cx='9.99988' cy='10' r='2' fill='%23949494'/%3E%3Ccircle cx='2' cy='18' r='2' fill='%23949494'/%3E%3Ccircle cx='9.99988' cy='18' r='2' fill='%23949494'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			color: #949494;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 17px;
			width: 15px;
			height: 20px;
			cursor: grab;
		}

		h5 {
			font-size: 18px;
			font-weight: 500;
			margin: 0;
		}
	}

	.app-card-content {
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
	}
	.app-card-footer {
		text-transform: capitalize;
		.button {
			width: 100%;
		}
		margin-top: auto;
	}
}
