.popup.popup-warn.collection-popup .popup-content {
  max-width: 430px;
}

.popup.popup-warn .popup-content{
  header {
    margin-bottom: 15px;
  }

  .popup-warn-buttons {
    gap: 10px;
  }
} 

.mfa-popup {
  .input-container {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin: 20px 0;
  }

  .mfa-input {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }

  .button.primary-button {
    background-color: #4C4FFF;
    width: 65px;
    height: 29px;
    
    &:hover {
      background-color: #8789FF;
    }
  }

  .button.secondary-button {
    border: none;
    width: 65px;
    height: 29px;
    color: #535353;

    &:hover {
      background-color: #E7E7E7;
      color: #393939;
      border-color: #ACACAC;
    }
  }

  .error-message{
    color: #FF0000;
    font-size: 14px;
    margin-top: 5px;
  }
}

