.subscriptions {
  .is-active {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50em;

    &.yes {
      background: #70c706;
    }

    &.no {
      background: #7e0202;
    }
  }

  .button {
    border: none !important;
    background: none;
    padding: 0 !important;
    margin: 0 5px;
    width: auto;
    min-width: 0;
    opacity: 1;
    color: #6881e9;
    
    &.delete {
      color: #7e0202;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}