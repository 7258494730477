@import '~@commonninja/commonninja-styleguide-react/lib/esm/components/_vars';

.developer-apps {
	flex: 1;
	padding: 120px 0 100px;

	@media screen and (max-width: $breakpoint-2) {
		padding: 120px 40px;
	}

	@media screen and (max-width: $breakpoint-3) {
		padding: 110px 20px;
	}

	.inner-nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: auto;
		max-width: 1120px;

		@media screen and (max-width: 660px) {
			flex-direction: column;
			gap: 20px;
			text-align: center;
		}

		.search-input {
			position: relative;
			input {
				width: 250px;

				&::placeholder {
					color: #aab0be;
				}
			}

			.search-icon {
				position: absolute;
				color: #aab0be;
				font-size: 14px;
				top: 50%;
				transform: translateY(-50%);
				right: 10px;
			}
		}
	}

	.developer-page hgroup {
		padding: 120px 20px 0;

		.inner-nav.align-end {
			@media screen and (max-width: 660px) {
				align-items: center;
			}
		}
	}

	.apps-wrapper {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		max-width: 1120px;
		margin: auto;
		gap: 20px;
		justify-content: center;

		.no-results {
			font-size: 20px;
			letter-spacing: 0.1;
			text-align: center;
			line-height: normal;
			font-size: 24px;
			margin-top: 100px;

			span {
				cursor: pointer;
				color: var(--colorDarkGraphPrimary);
				font-weight: 500;
				font-size: 18px;
				margin-top: 10px;
				display: inline-block;

				&:hover {
					color: var(--colorActive);
				}
			}
		}

		.app {
			background: var(--colorPureWhite);
			border-radius: 5px;
			width: 360px;
			min-height: 160px;
			padding: 20px;
			flex-direction: column;
			justify-content: space-between;
			transition: all 0.2s;
			height: auto;

			@media screen and (max-width: 830px) {
				width: 100%;
			}

			p {
				margin: 0;
			}

			section {
				gap: 15px;

				img {
					width: 80px;
					height: 80px;
					object-fit: cover;
				}
			}

			.app-info {
				padding: 2px 0 10px;
				flex: 1;

				h4 {
					font-family: Spartan;
					font-size: 20px;
					line-height: 1.5em;
					margin-bottom: 10px;
					font-weight: 500;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				p {
					color: var(--colorDarkGraphSecondary);
				}
			}

			footer {
				justify-content: space-between;
				font-size: 12px;
				flex-direction: row-reverse;
				font-weight: 300;
				color: var(--colorDarkGraphSecondary);

				.app-status {
					text-transform: uppercase;
					&.highlight {
						font-weight: 500;
					}
				}
			}

			&.app-add {
				color: var(--colorDarkGraphPrimary);
				position: relative;
				transition: all 0.2s;

				a {
					align-items: center;
					justify-content: center;
					text-align: center;
					flex-direction: column;
					gap: 20px;
					font-family: Spartan;
					font-style: normal;
					font-weight: 300;
					font-size: 20px;
					line-height: 160%;
					color: #fcfcfd;
					text-decoration: none;

					svg {
						font-size: 30px;
					}
				}

				&:hover {
					// background-color: var(--colorDarkGraphSecondary);
					filter: none;
				}
			}
		}
	}
}

header.top-bar {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 86px;
	background: var(--backgroundMenu);
	.logo {
		padding: 30px 40px;

		a {
			outline: none;
			text-decoration: none;
			flex-direction: column;
			align-items: center;
			max-width: 200px;
			img {
				width: 100%;
			}

			p {
				outline: none;
				font-family: 'Space Grotesk';
				font-weight: 300;
				font-size: 16px;
				line-height: 1.3em;
				margin: 0 5px;
				color: #fcfcfd;
				@media screen and (max-width: 580px) {
					display: none;
				}
			}
		}
	}
}

.popup.app-name-popup {
	.popup-content {
		max-width: 500px;
		text-align: center;
	}

	p {
		font-size: 18px;
		margin: 0 0 15px;
	}
}
