.collections-container {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	max-width: 930px;

	.collection-card {
		padding: 20px 30px;
		background-color: #ffffff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-width: 290px;
		flex: 270px;

		&.app-card-add {
			flex-direction: row;
			justify-content: center;
			gap: 10px;
			height: 145px;
		}

		@media screen and (max-width: 985px) {
			max-width: 100%;
		}

		.top-content {
			margin-bottom: 23px;

			h3 {
				color: #1e2a3b;
				font-weight: 500;
				font-size: 18px;
				margin: 0;
				margin-bottom: 1px;
			}
		}
	}
}

.popup.popup-warn.collection-popup {
	.popup-content {
		max-width: 600px;
		width: 90%;
		background-color: #fff;

		&.faded-in {
			padding: 20px;
		}
		h3 {
			width: 100%;
			font-size: 20px;
			padding: 0 0 10px;
			border-bottom: 1px solid #14141430;
		}

		.horizontal {
			label {
				margin-bottom: 10px;
				margin-top: 0px;
			}
		}

		label {
			margin-bottom: 10px;
			margin-top: 20px;
		}
		input:not(input[type='checkbox']) {
			background-color: transparent;
		}
	}
}
